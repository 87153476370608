import { AccountTypeService } from './account-type.service';
import { FormService } from './form.service';
import { Consents } from './../../sections/consents/consents.component';
import { BmoRelationship } from './../../sections/bmo-relationship/bmo-relationship.component';
import { AccountPassword } from './../../sections/account-password/account-password.component';
import { RegulatoryDisclosure } from './../../sections/regulatory-disclosures/regulatory-disclosures.component';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ApplicationService } from '../../shared/service/application.service';
import { SelectedCitizenCountryList, SelectedCountry } from './../../sections/personal-information/personal-information.component';
import { Utils } from '../../shared/utils/utils.class';
import { isNil, filter, includes, some, clone, cloneDeep, find } from 'lodash';
import {
    EMPLOYMENTSTATUS, CANADA, productTypeAD, INVESTMENT_KNOWLEDGE_TYPES, COUNTRY_CODE, ACCOUNT_TYPE,
    SECURITYHOLDER_MATERIAL_TYPES, TAX_DOCUMENT_TYPES, APP_ROUTES, APPLICATION_STATUS, DOCUMENT_STATUS, SUCCESS_RESPONSE,
    QUEBEC, PRODUCT, SPOUSAL_RRSP_ACCOUNT, ACCOUNT_CODES, RIF_PAYMENT_METHOD, INTERNAL
}
    from '../../config/app-config.constants';
import { AccountDetail } from './../../sections/account-detail/account-detail.component';
import { EmploymentInformation } from './../../sections/employment-information/employment-information.component';
import { PersonalInformation } from './../../sections/personal-information/personal-information.component';
import { ContactInformation } from './../../sections/contact-information/contact-information.component';
import { FinancialInformation } from './../../sections/financial-details/financial-details.component';
import { FamilyInformation } from './../../sections/family-information/family-information.component';
// import { SelectAccount } from './../../sections/select-account/select-account.component';
import { AccountInvestmentObjectives } from './../../sections/investment-objectives/investment-objectives.component';
import { InvestmentKnowledge } from './../../sections/investment-knowledge/investment-knowledge.component';
import { InvestorProfile } from './../../sections/investor-profile/investor-profile.component';
import { PrefillService } from '../../shared/service/prefill.service';
import { BeneficiaryInformationObj } from './../../sections/beneficiary/beneficiary.component';
import { SelectAccount } from '../../sections/select-account/select-account.component';
import { RiskAndReturn } from './../../sections/risk-and-return/risk-and-return.component';
import { WithdrawalInformationObj } from '../../sections/withdrawal/withdrawal.component';
import { SetExpectationObject } from './../../sections/set-expectation/set-expectation.component';
@Injectable({
    providedIn: 'root'
})

export class PartyService {

    public primaryApplicant: any;
    public primaryAccount: any;
    public spouse: any;
    public accountDetail: SelectAccount;
    public contactInformation: ContactInformation;
    public personalInformation: PersonalInformation;
    public employmentInformation: EmploymentInformation;
    public familyInformation: FamilyInformation;
    public beneficiaryInformation: BeneficiaryInformationObj[] = [];
    public financialInformation: FinancialInformation;
    public investmentObjectives: AccountInvestmentObjectives;
    public riskAndReturn: RiskAndReturn;
    public investmentKnowledge: InvestmentKnowledge;
    public investorProfile: InvestorProfile;
    public regulatoryInformation: RegulatoryDisclosure;
    public accountPasswordInformation: AccountPassword;
    public bmoRelationship: BmoRelationship;
    public withdrawalInformationObj: WithdrawalInformationObj;
    public consents: Consents;
    //public selectAccount: SelectAccount = new SelectAccount();
    public selectAccount: any;
    public isCurrentLangEnglish: any;
    public isCurrentLangFrench: any;
    public beneficiaries: any;
    public setExpectation: SetExpectationObject;

    constructor(public applicationService: ApplicationService,
        private formService: FormService,
        private prefillService: PrefillService,
        public accountTypeService: AccountTypeService,
    ) {

        //this.applicationService.getReferences(Utils.getLanguage());

    }

    /**Get Primary Account */
    getPrimaryApplicant(applicationObject) {
        this.primaryApplicant = applicationObject.parties[0];

        return this.primaryApplicant;
    }

    /**Get Primary Account */
    getPrimaryAccount(applicationObject) {
        this.primaryAccount = applicationObject.accounts[0];

        return this.primaryAccount;
    }

    getBeneficiaries(applicationObject) {
        this.beneficiaries = applicationObject.beneficiaries;
    }

    /**Get Primary Account's Spouse */
    getSpouse(applicationObject) {
        // this.spouse = (this.primaryApplicant.personal.identity.maritalStatus === '100002' ||
        //     this.primaryApplicant.personal.identity.maritalStatus === '100003') ? applicationObject.parties[1] : null;
        this.spouse = null;
        if (this.primaryApplicant.personal.identity.maritalStatus === '100002' ||
            this.primaryApplicant.personal.identity.maritalStatus === '100003') {

            (this.applicationService.getApplicationData().parties || []).forEach((party) => {

                if (this.primaryApplicant.spousePartyRefId === party.partyRefId) {
                    this.spouse = party;

                }
            });
        } else {
            this.spouse = null;
        }
        return this.spouse;
    }
    setFormDataForEsign() {
        const primaryApplicant = Utils.getPrimaryApplicant()
        this.contactInformation = new ContactInformation();
        this.personalInformation = new PersonalInformation();
        this.formService.setFormData('personalInformation', this.personalInformation);
        this.formService.setFormData('contactInformation', this.contactInformation);
        this.formService.forms.personalInformation.data.firstname = primaryApplicant.personal.identity.legalName.firstName;
        this.formService.forms.personalInformation.data.lastname = primaryApplicant.personal.identity.legalName.lastName;
        this.formService.forms.personalInformation.data.emailAddress = primaryApplicant.personal.identity.primaryEmailAddress;
    }

    /**Reverse mapping for personal info */
    getPersonalInformation(personalInformation) {
        /* const title = this.primaryApplicant.personal.identity.legalName.title;
        if (title) {
            personalInformation.prefix = title;
        } else {
            personalInformation.prefix = "null";
        } */
        personalInformation.firstname = this.primaryApplicant.personal.identity.legalName.firstName;
        personalInformation.initial = this.primaryApplicant.personal.identity.legalName.middleName || '';
        personalInformation.lastname = this.primaryApplicant.personal.identity.legalName.lastName;
        personalInformation.likeToUsePreferredName = this.primaryApplicant.personal.identity.hasPreferredName;
        if (this.primaryApplicant.personal.identity.preferredName) {
            personalInformation.preferredFirstName = this.primaryApplicant.personal.identity.preferredName.firstName;
            if (this.primaryApplicant.personal.identity.preferredName.middleName) {
                personalInformation.preferredMiddleName = this.primaryApplicant.personal.identity.preferredName.middleName;
            }
            personalInformation.preferredLastName = this.primaryApplicant.personal.identity.preferredName.lastName;
        }
        personalInformation.sinNumber = this.primaryApplicant.personal.identity.socialInsuranceNumber;
        personalInformation.dateOfBirth = this.primaryApplicant.personal.identity.dateOfBirth;
        personalInformation.currentLanguage = this.primaryApplicant.personal.identity.preferredLanguage;
        personalInformation.emailAddress = this.primaryApplicant.personal.identity.primaryEmailAddress;

        /**To fix defect MWB-10737 */
        let isCanadian = this.primaryApplicant.personal.identity.citizenships[0] == '100000' ? true : false;

        if (!isCanadian) {//if not a canadian citizen
            personalInformation.citizenship = "";
            personalInformation.citizenshipLabel = "";

        } /**If a country is in both citizenships and tax residency list*/
        else if (!isCanadian && Utils.checkIfCountryBothCitizenAndTaxResident(this.primaryApplicant.personal.identity.citizenships[0], this.primaryApplicant.personal.residencyForTax)) {
            personalInformation.citizenship = "";
            personalInformation.citizenshipLabel = "";
        }
        else {
            personalInformation.citizenship = this.primaryApplicant.personal.identity.citizenships[0];
            personalInformation.citizenshipLabel = (this.primaryApplicant.personal.identity.citizenships[0] === CANADA.code) ? CANADA.label :
                Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[0]);
        }


        /**In case of TFSA/CASH */
        if (Utils.isTFSA() || Utils.isCash() || Utils.isMargin()) {
            this.setTFSACashTaxResidencyAndAlternateCitizenship(personalInformation)
        }
        /**In case of RRSP */
        else if ((Utils.isRRSP() || Utils.isSpousalRRSP() || Utils.isRIF())) {
            this.setRRSPAlternateCitizenship(personalInformation);
        }
        /***** Below line of the do the revers mapping of selectedCitizenCountries object array */
        if (this.applicationService.getCRAtaxresidencyEnabledFeatureFlag()) {
            setTimeout(() => {
                let citizenArray: any = this.formService.getPayload().parties[0].personal.identity.citizenships;

                (citizenArray || []).forEach((citizen: any, index: any) => {
                    if (citizen != COUNTRY_CODE.CANADA && citizen != COUNTRY_CODE.USA) {
                        if(!find(personalInformation.selectedCitizenCountries, {code: citizen})) {
                            (personalInformation.selectedCitizenCountries || []).push(
                                {
                                    id: "citizenCountry" + personalInformation.citizenCountryIndex++,
                                    code: citizen,
                                })
                        }

                    }
                });
            }, 1);
        }
        /*******************/



        if (personalInformation.selectedCountries && personalInformation.selectedCountries.length > 1) {
            let countryCodes: any = [];
            let countries: any = [];
            personalInformation.selectedCountries.forEach((country) => {
                if (countryCodes.indexOf(country.code) == -1) {
                    countries.push(country);
                    countryCodes.push(country.code);
                }
            });
            personalInformation.selectedCountries = countries;
        }

        if (personalInformation.firstname === '') {
            return null; // In last session user has not filled data in PersonalInfo page
        } else {
            return personalInformation;
        }


    }

    /**Reverse mapping for contact info */
    getContactInformation(contactInfomation) {

        // Primary Address
        contactInfomation.streetNumberName = this.primaryApplicant.personal.residence.primaryAddress.streetAddress;
        contactInfomation.unit = this.primaryApplicant.personal.residence.primaryAddress.unitNumber;
        contactInfomation.city = this.primaryApplicant.personal.residence.primaryAddress.city;
        contactInfomation.province = this.primaryApplicant.personal.residence.primaryAddress.province;

        /**Province Label */
        let provinces = this.applicationService.appReferences.references.countries[0].stateProvinces;
        contactInfomation.provinceLabel = Utils.getProvinceLabel(provinces, contactInfomation.province) ? Utils.getProvinceLabel(provinces, contactInfomation.province).label : null;

        contactInfomation.postalCode = this.primaryApplicant.personal.residence.primaryAddress.postalCode;

        // Address Number of Years?/
        // contactInfomation.addressNumberOfYears = null;

        // previous residence???
        // contactInfomation.previousResidenceLocation = null;

        // primary residence?
        // contactInfomation.primaryResidenceLocated = null;

        // same mailing address
        if (this.primaryApplicant.personal.residence.alternateAddress.streetAddress) {
            contactInfomation.sameMailingAddress = "No";
        } else {
            contactInfomation.sameMailingAddress = "Yes";
        }

        // Different address
        contactInfomation.diffAddressRequired = this.primaryApplicant.personal.residence.hasAlternateAddress;

        if (contactInfomation.diffAddressRequired === true) {
            contactInfomation.diffPostalCode = this.primaryApplicant.personal.residence.alternateAddress.postalCode;
        }

        contactInfomation.diffStreetNumberName = this.primaryApplicant.personal.residence.alternateAddress.streetAddress;
        contactInfomation.diffUnit = this.primaryApplicant.personal.residence.alternateAddress.unitNumber;
        contactInfomation.diffCity = this.primaryApplicant.personal.residence.alternateAddress.city;
        contactInfomation.diffProvince = this.primaryApplicant.personal.residence.alternateAddress.province;

        /**Diff Province Label */
        contactInfomation.diffProvinceLabel = Utils.getProvinceLabel(provinces, contactInfomation.diffProvince) ? Utils.getProvinceLabel(provinces, contactInfomation.diffProvince).label : null;

        if (this.applicationService.getDataRationalizationFeatureFlag() === false) {
            // Phone number
            if (Utils.nullCheck(this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber) && this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber !== '') { // MOBILE NUMBER
                contactInfomation.phoneType = 'contactInformation.labels.phoneMobile';
                contactInfomation.phoneNumber = this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber;
            } else { // PHONE NUMBER OR WORK
                if (Utils.nullCheck(this.primaryApplicant.personal.residence.primaryPhone.phoneNumber) && this.primaryApplicant.personal.residence.primaryPhone.phoneNumber !== '') { // PHONE NUMBER
                    contactInfomation.phoneType = 'contactInformation.labels.phoneHome';
                    contactInfomation.phoneNumber = this.primaryApplicant.personal.residence.primaryPhone.phoneNumber;
                } else if (Utils.nullCheck(this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneNumber) && this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneNumber !== '') { // WORK NUMBER
                    contactInfomation.phoneType = 'contactInformation.labels.phoneWork';
                    contactInfomation.phoneNumber = this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneNumber;
                    contactInfomation.phoneExtension = this.primaryApplicant.personal.employment.primaryBusinessPhone.phoneExtension;
                }
            }
        } else if (this.applicationService.getDataRationalizationFeatureFlag() === true) {
            // Phone number
            if (Utils.nullCheck(this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber) && this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber !== '') {
                contactInfomation.phoneNumber = this.primaryApplicant.personal.residence.secondaryPhone.phoneNumber;
                contactInfomation.phoneExtension = this.primaryApplicant.personal.residence.secondaryPhone.phoneExtension;
            }
        }


        if (contactInfomation.phoneNumber === '') {
            return null; // In last session user has not filled data in PersonalInfo page
        } else {
            return contactInfomation;
        }

    }

    /**Reverse mapping for Employment info */
    getEmploymentInformation(employmentInformation) {

        employmentInformation.employmentStatus = this.primaryApplicant.personal.employment.employmentStatus;
        employmentInformation.selectOccupation = this.primaryApplicant.personal.employment.occupation;
        employmentInformation.streetNumberName = this.primaryApplicant.personal.employment.employmentAddress.streetAddress;
        employmentInformation.selectNatureOfBusiness = this.primaryApplicant.personal.employment.natureOfBusiness;

        if (employmentInformation.employmentStatus === EMPLOYMENTSTATUS.RETIRED) {
            employmentInformation.selectPreviousOccupation = this.primaryApplicant.personal.employment.occupation;
            employmentInformation.selectNatureOfBusiness = this.primaryApplicant.personal.employment.natureOfBusiness;
        }

        employmentInformation.selectCountry = this.primaryApplicant.personal.employment.employmentAddress.country;
        employmentInformation.unit = this.primaryApplicant.personal.employment.employmentAddress.unitNumber;
        employmentInformation.city = this.primaryApplicant.personal.employment.employmentAddress.city;
        employmentInformation.city = this.primaryApplicant.personal.employment.employmentAddress.city;
        employmentInformation.province = this.primaryApplicant.personal.employment.employmentAddress.province;
        employmentInformation.employerName = this.primaryApplicant.personal.employment.employerBusinessName;
        employmentInformation.jobTitle = this.primaryApplicant.personal.employment.jobTitle;
        employmentInformation.postalCode = this.primaryApplicant.personal.employment.employmentAddress.postalCode;
        employmentInformation.bmoGroupOption = this.primaryApplicant.personal.employment.bmoGroup;
        if (employmentInformation.zipCode !== null && employmentInformation.zipCode !== '') {

            employmentInformation.zipCode = this.primaryApplicant.personal.employment.employmentAddress.postalCode;
        }
        // MWB-10734 Fix
        if (this.primaryApplicant.personal.employment.employmentAddress.country === EMPLOYMENTSTATUS.COUNTRY.USA) {
            employmentInformation.zipCode = this.primaryApplicant.personal.employment.employmentAddress.postalCode;
            employmentInformation.state = this.primaryApplicant.personal.employment.employmentAddress.province;
        }
        //MWB-10734 Fix

        // if (employmentInformation.isBMOEmployee) {

        // } else {
        //     delete this.primaryApplicant.personal.employment.bmoGroup;
        // }
        if (employmentInformation.employmentStatus === '') {
            return null; // In last session user has not filled data in employmentInfo page
        } else {
            return employmentInformation;
        }

    }
    /**Reverse mapping for Family info */
    getFamilyInformation(familyInformation, spouse) {
        familyInformation.maritalStatus = this.primaryApplicant.personal.identity.maritalStatus;
        if (familyInformation.isMarried() && spouse) {
            this.spouse = spouse;
            familyInformation.employmentStatus = this.spouse.personal.employment.employmentStatus;
            familyInformation.firstName = this.spouse.personal.identity.legalName.firstName;
            familyInformation.initial = this.spouse.personal.identity.legalName.middleName ?
                this.spouse.personal.identity.legalName.middleName : '';
            familyInformation.lastName = this.spouse.personal.identity.legalName.lastName;
            familyInformation.jobTitle = this.spouse.personal.employment.jobTitle;
            familyInformation.employerName = this.spouse.personal.employment.employerBusinessName;
            familyInformation.selectOccupation = this.spouse.personal.employment.occupation;
            familyInformation.selectNatureOfBusiness = this.spouse.personal.employment.natureOfBusiness;
            familyInformation.bmoGroupOption = this.spouse.personal.employment.bmoGroup;
            familyInformation.isBMOEmployee = this.spouse.personal.employment.isBmoEmployee;
            familyInformation.hasSuccessorAnnuitant = this.primaryAccount.hasSuccessorAnnuitant;

            if ((Utils.isTFSA() || Utils.isRIF()) && this.primaryAccount.hasSuccessorAnnuitant === true) {
                familyInformation.sinNumber = this.spouse.personal.identity.socialInsuranceNumber;
                familyInformation.spouseDateOfBirth = this.spouse.personal.identity.dateOfBirth;
            }
            if ((Utils.isTFSA() || Utils.isRIF()) && Utils.isBranchSDMultiAccountFlow()) {
                familyInformation.spouseDateOfBirth = this.spouse.personal.identity.dateOfBirth;
            }

        }


        /**In case of SpousalRRSP */
        if (Utils.isSpousalRRSP()) {
            if (familyInformation.isMarried()) {
                familyInformation.spousalMaritalStatus = this.primaryApplicant.personal.identity.maritalStatus;
                familyInformation.sinNumber = this.spouse.personal.identity.socialInsuranceNumber;
            }
        }

        familyInformation.dependents = this.primaryApplicant.personal.identity.hasDependents;
        familyInformation.dependentsCount = this.primaryApplicant.personal.identity.numOfDependents;
        if (familyInformation.maritalStatus === '') {
            return null; // In last session user has not filled data in Familyinfo page

        } else {
            return familyInformation;
        }
    }

    /**Reverse mapping for Beneficiary info */
    getBeneficiaryInformation(beneficiaryInformation) {

        this.formService.getPayload().accounts[0].hasBeneficiaries = this.primaryAccount.hasBeneficiaries;

        if (this.primaryAccount.hasBeneficiaries) {

            for (let i = 0; i < this.primaryAccount.beneficiaries.length; i++) {
                // add into beneficiary
                if (beneficiaryInformation[i]) {
                    beneficiaryInformation[i].firstName = this.beneficiaries[i].name.firstName;
                    beneficiaryInformation[i].lastName = this.beneficiaries[i].name.lastName;
                    beneficiaryInformation[i].relationshipToPlanHolder = this.beneficiaries[i].relationshipToPlanHolder;
                    beneficiaryInformation[i].dateOfBirth = this.beneficiaries[i].dateOfBirth;

                    beneficiaryInformation[i].entitlementPercentage = this.primaryAccount.beneficiaries[i].entitlementPercentage;

                } else {
                    this.beneficiaryInformation.push(new BeneficiaryInformationObj());

                    beneficiaryInformation[i].firstName = this.beneficiaries[i].name.firstName;
                    beneficiaryInformation[i].lastName = this.beneficiaries[i].name.lastName;
                    beneficiaryInformation[i].relationshipToPlanHolder = this.beneficiaries[i].relationshipToPlanHolder;
                    beneficiaryInformation[i].dateOfBirth = this.beneficiaries[i].dateOfBirth;

                    beneficiaryInformation[i].entitlementPercentage = this.primaryAccount.beneficiaries[i].entitlementPercentage;
                }
            }
        }

        if (this.primaryAccount.hasBeneficiaries === '') {
            return null; // In last session user has not filled data in Familyinfo page

        } else {
            return beneficiaryInformation;
        }
    }

    /**Reverse mapping for Finacial info */
    getFinancialInformation(financialInformation) {


        financialInformation.otherSourceOfWealth = this.primaryApplicant.financial.other.otherSourceOfWealth;
        financialInformation.sourcesOfWealth = this.primaryApplicant.financial.other.sourcesOfWealth;
        financialInformation.netWorth = this.primaryApplicant.financial.status.netWorth.fixedAssets;
        financialInformation.netLiquidAssets = this.primaryApplicant.financial.status.netWorth.liquidAssets;
        financialInformation.grossAnnualIncome = this.primaryApplicant.financial.status.income.annualEmploymentIncome;
        financialInformation.annualIncReq = this.primaryAccount.investmentObjectives.annualIncReq;
        financialInformation.borrowingMoney = this.primaryApplicant.financial.other.isBorrowingToInvest;
        financialInformation.saveInvestibleAsset = this.primaryAccount.investmentObjectives.investAssetPercentage;
        financialInformation.netLiquidAssets = this.primaryApplicant.financial.status.netWorth.liquidAssets;

        Utils.netWorth = financialInformation.netWorth; // Using it for GTM DataLayer

        if (financialInformation.netWorth === '') {
            return null; // In last session user has not filled data in FinancialInfo page
        } else {
            return financialInformation;
        }

    }

    /**Reverse mapping for Investment Knowledge */
    getInvestmentKnowledge(investmentKnowledge) {

        investmentKnowledge.ilInvestmentKnowledge = this.primaryApplicant.financial.investment.ilInvestmentKnowledge;
        investmentKnowledge.pastExperience = this.primaryApplicant.financial.investment.pastExperience;
        (this.primaryApplicant.financial.investment.ilInvestmentKnowledge != INVESTMENT_KNOWLEDGE_TYPES.NONE) ? this.primaryApplicant.financial.investment.pastExperience : [];
        investmentKnowledge.ilInvestmentTimeHorizon = this.primaryAccount.ilInvestmentTimeHorizon;
        investmentKnowledge.investmentTimeHorizonForWithDraw = this.primaryAccount.investmentTimeHorizonForWithDraw;

        return investmentKnowledge;
    }

    /**Reverse mapping for Risk And Return */
    getRiskAndReturn(riskAndReturn) {

        riskAndReturn.riskTolerance = this.primaryAccount.investmentObjectives.riskToleranceQ1;
        riskAndReturn.riskReturnInvestment = this.primaryAccount.investmentObjectives.riskToleranceQ2;

        if (riskAndReturn.riskTolerance === '') {
            return null; // In last session user has not filled data in FinancialInfo page
        } else {
            return riskAndReturn;
        }
    }


    /**Reverse mapping for Investment Objectives */
    getInvestmentObjectives(investmentObjectives) {

        investmentObjectives.income = this.primaryAccount.accountInvestmentObjectives.income.toString();
        investmentObjectives.shortTerm = this.primaryAccount.accountInvestmentObjectives.shortTerm.toString();
        investmentObjectives.mediumTerm = this.primaryAccount.accountInvestmentObjectives.mediumTerm.toString();
        investmentObjectives.longTerm = this.primaryAccount.accountInvestmentObjectives.longTerm.toString();

        if (investmentObjectives.income === '') {
            return null; // In last session user has not filled data in FinancialInfo page
        } else {
            return investmentObjectives;
        }
    }

    /**Reverse mapping for Investor Profile */
    getInvestorProfile(investorProfile) {

        investorProfile.ilRiskTolerance = this.primaryAccount.investmentObjectives.ilRiskTolerance;
        investorProfile.objective = this.primaryAccount.investmentObjectives.objective;
        investorProfile.riskLevelAcceptedForAgeSeventy = this.primaryAccount.investmentObjectives.riskLevelAcceptedForAgeSeventy;

        if (investorProfile.ilRiskTolerance === '') {
            return null; // In last session user has not filled data in FinancialInfo page
        } else {
            return investorProfile;
        }
    }


    /**Reverse mapping for RegulatoryDisclosure info */
    getRegulatoryDisclosure(regulatoryInformation) {
        /**Intended use of account */
        if (Utils.isSubProductAD() || (!Utils.isSubProductAD() && (Utils.isCash() || Utils.isMargin()))) {
            if (Utils.getApplicationObject()?.accounts?.length > 1) {
                const cashOrMarginIndex = Utils.getApplicationObject().accounts.findIndex(account => account.type == ACCOUNT_CODES.CASH);
                regulatoryInformation.intendedUseOfAccount = Utils.getApplicationObject().accounts[cashOrMarginIndex]?.intendedUse;
            } else {
                regulatoryInformation.intendedUseOfAccount = this.primaryAccount.intendedUse;
            }
        }

        /**Relationships */
        regulatoryInformation.relationShip = Utils.yesOrNoRadioMapper(this.primaryApplicant.regulatoryDisclosures.iirocMember);
        regulatoryInformation.memberFromLabel = this.primaryApplicant.regulatoryDisclosures.iirocOrganization;
        if (this.primaryApplicant.regulatoryDisclosures.iirocMember === true) {
            regulatoryInformation.memberFirm = this.primaryApplicant.regulatoryDisclosures.iirocOrganization;
        }

        /**Insider relationships */
        regulatoryInformation.insiderRelationship = Utils.yesOrNoRadioMapper(this.primaryApplicant.regulatoryDisclosures.haveInsiderRelationship);

        let lengthOfInsiderRelationships = this.primaryApplicant.regulatoryDisclosures.insiderRelationships.length;

        for (let i = 0; i < lengthOfInsiderRelationships; i++) {
            regulatoryInformation.insiderRelationships.push(this.primaryApplicant.regulatoryDisclosures.insiderRelationships[i]);
        }

        /**Spouse Insider relationship */
        regulatoryInformation.spouseInsiderRelationship = Utils.yesOrNoRadioMapper(this.primaryApplicant.regulatoryDisclosures.isReportingInsider);

        let lengthOfReportingInsiderRelationships = this.primaryApplicant.regulatoryDisclosures.reportingInsiderRelationships.length;

        for (let i = 0; i < lengthOfReportingInsiderRelationships; i++) {
            regulatoryInformation.spouseInsiderRelationships.push(this.primaryApplicant.regulatoryDisclosures.reportingInsiderRelationships[i]);
        }

        /**Significant shareholder */
        regulatoryInformation.shareholderRelationship = Utils.yesOrNoRadioMapper(this.primaryApplicant.regulatoryDisclosures.significantShareholder);

        let lengthOfsignificantShareholdings = this.primaryApplicant.regulatoryDisclosures.significantShareholdings.length;

        for (let i = 0; i < lengthOfsignificantShareholdings; i++) {
            regulatoryInformation.shareholderRelationships.push(this.primaryApplicant.regulatoryDisclosures.significantShareholdings[i]);
        }

        /**Control relationships */
        regulatoryInformation.controlRelationship = Utils.yesOrNoRadioMapper(this.primaryApplicant.regulatoryDisclosures.controlRelationship);

        let lengthOfcontrolRelationships = this.primaryApplicant.regulatoryDisclosures.controlRelationships.length;

        for (let i = 0; i < lengthOfcontrolRelationships; i++) {
            regulatoryInformation.controlRelationships.push(this.primaryApplicant.regulatoryDisclosures.controlRelationships[i]);
        }

        /**Policitcally exposed person */
        regulatoryInformation.governmentalRelationship = Utils.yesOrNoRadioMapper(this.primaryApplicant.regulatoryDisclosures.isPoliticallyExposedPerson);

        if (this.primaryApplicant.regulatoryDisclosures.politicallyExposedPersonType === 'D') {
            regulatoryInformation.governmentRelationPosition = 'Domestic';
        } else {
            regulatoryInformation.governmentRelationPosition = 'Foreign';
        }

        if (regulatoryInformation.governmentRelationPosition === 'Domestic') {//Domestic  Position
            regulatoryInformation.selectDomesticTitle = this.primaryApplicant.regulatoryDisclosures.politicallyExposedPersonPosition;
        } else {//Foregin Position
            regulatoryInformation.selectForeignTitle = this.primaryApplicant.regulatoryDisclosures.politicallyExposedPersonPosition;
        }

        if (regulatoryInformation.selectDomesticTitle === '') {
            return null; // In last session user has not filled data in RegulatoryDisclosure page
        } else {
            return regulatoryInformation;
        }
    }

    /**Reverse mapping for ConsentInformation info */
    getConsentsInformation(consent) {

        consent.personalInfo = this.primaryApplicant.preferences.sharePersonalInfo;
        consent.marketingPref = this.primaryApplicant.preferences.receiveDirectMarketingMaterials;
        consent.shareholder = this.primaryApplicant.preferences.discloseBeneficialOwner;
        consent.securityholder = this.primaryApplicant.preferences.receiveSecurityHolderMaterials;
        if (this.primaryApplicant.preferences.receiveSecurityHolderMaterials == '2') {
            consent.securityholder = false;
        } else if (this.primaryApplicant.preferences.receiveSecurityHolderMaterials == '1') {
            consent.securityholderOption = SECURITYHOLDER_MATERIAL_TYPES.ProxiesAnualReports;
            consent.securityholder = true;
        } else if (this.primaryApplicant.preferences.receiveSecurityHolderMaterials == '3') {
            consent.securityholderOption = SECURITYHOLDER_MATERIAL_TYPES.Proxies;
            consent.securityholder = true;
        }

        if (this.primaryApplicant.preferences.iseTaxConsentProvided === true) {
            consent.taxDocuments = TAX_DOCUMENT_TYPES.Electronic;
        }
        else if (this.primaryApplicant.preferences.iseTaxConsentProvided === false) {
            consent.taxDocuments = TAX_DOCUMENT_TYPES.ElectronicAndMail;
        }
        if (consent.personalInfo === '') {
            return null; // In last session user has not filled data in Consents page
        } else {
            return consent;

        }
    }

    /**Reverse mapping for BMORelationshipInformation info */

    getBMORelationshipInformation(bmoRelationship) {
        let bmoProductsList = this.applicationService.appReferences.references.bmoClientRelationshipType;
        if ((Utils.isRRSP() || Utils.isTFSA() || Utils.isSpousalRRSP() || Utils.isRIF()) && !Utils.isCash() && !Utils.isMargin()) {
            bmoRelationship.bmoRelationshipClient = this.primaryApplicant.bmoRelationship.isApplicantExistingClient;
            bmoRelationship.accountNumber = this.primaryApplicant.bmoRelationship.bmoAccountNumber;
            bmoRelationship.bmoProduct = this.primaryApplicant.bmoRelationship.bmoProduct;

            if (bmoRelationship.isApplicantExistingClient) {
                bmoRelationship.bmoProductCode = this.primaryApplicant.bmoRelationship.bmoProduct;
                for (const item of bmoProductsList) {
                    if (item.code === bmoRelationship.bmoProductCode) {
                        this.bmoRelationship.bmoProductLabel = item.label;
                    }
                }
            }
        }
        bmoRelationship.hasBMODebitCard = this.primaryApplicant.bmoRelationship.hasBMODebitCard;
        bmoRelationship.consentToAccountLink = this.primaryApplicant.bmoRelationship.consentToAccountLink;
        if (bmoRelationship.hasBMODebitCard) {
            bmoRelationship.debitCardNumber = this.primaryApplicant.bmoRelationship.bmoAccountNumber;
            this.primaryApplicant.bmoRelationship.isApplicantExistingClient = true;
            this.primaryApplicant.bmoRelationship.bmoProduct = '1';
        } else {
            bmoRelationship.newDebitCardYesNo = this.primaryApplicant.bmoRelationship.sendNewBMODebitCard;
            bmoRelationship.newDebitCardYesNo = Utils.yesOrNoRadioMapper(bmoRelationship.newDebitCardYesNo);
            bmoRelationship.isApplicantExistingClient =
                this.primaryApplicant.bmoRelationship.isApplicantExistingClient;
            if (bmoRelationship.isApplicantExistingClient) {
                bmoRelationship.bmoProductCode = this.primaryApplicant.bmoRelationship.bmoProduct;
                for (const item of bmoProductsList) {
                    if (item.code === bmoRelationship.bmoProductCode) {
                        this.bmoRelationship.bmoProductLabel = item.label;
                    }
                }
            }

        }
        bmoRelationship.hasBMODebitCard = Utils.yesOrNoRadioMapper(bmoRelationship.hasBMODebitCard);
        if (Utils.isCash() || Utils.isMargin()) {
            /* if(Utils.getApplicationObject()?.accounts?.length > 1) {
                const cashIndex = Utils.getApplicationObject().accounts.findIndex(account => account.type == ACCOUNT_CODES.CASH);
                bmoRelationship.accessUS = Utils.getApplicationObject().accounts[cashIndex]?.usDollarAccountLinkFlag;
            } else {
                bmoRelationship.accessUS = this.primaryAccount.usDollarAccountLinkFlag;
             }
            bmoRelationship.accessUS = Utils.yesOrNoRadioMapper(bmoRelationship.accessUS); */
            bmoRelationship.bmoRelationshipClient = this.primaryApplicant.bmoRelationship.isApplicantExistingClient;
        }
        if (bmoRelationship.bmoRelationshipClient === true) {
            bmoRelationship.bmoProduct = this.primaryApplicant.bmoRelationship.bmoProduct;
        }
        bmoRelationship.bmoRelationshipClient = Utils.yesOrNoRadioMapper(bmoRelationship.bmoRelationshipClient);
        bmoRelationship.isApplicantExistingClient = Utils.yesOrNoRadioMapper(bmoRelationship.isApplicantExistingClient);
        if (bmoRelationship.bmoRelationshipClient === '') {
            return null; // In last session user has not filled data in BmoRelationship page
        } else {
            return bmoRelationship;
        }
    }

    /**Reverse mapping for AccountPassword info */
    getAccountPassword(sampleAccountPasswordInformation) {

        /**SET IT TO '' */
        sampleAccountPasswordInformation.password = '';
        sampleAccountPasswordInformation.confirmPassword = '';

        if (sampleAccountPasswordInformation.password === '') {
            return null; // In last session user has not filled data in AccountPassword page
        } else {
            return sampleAccountPasswordInformation;
        }
    }

    getAccountDetails(accountInformation, applicationObject) {

        accountInformation.needShortSelling = Utils.yesOrNoRadioMapper(applicationObject.accounts[0].accountFeatures.isShortSelling);
        accountInformation.needOptionsTrading = Utils.yesOrNoRadioMapper(applicationObject.accounts[0].accountFeatures.isOptionTrading);
        accountInformation.yearsOfExperience = applicationObject.accounts[0].accountFeatures.optionsTrading.experienceInYears;
        accountInformation.tradingKnowledgeLevel = applicationObject.accounts[0].accountFeatures.optionsTrading.knowledge;
        accountInformation.selectedAccount = (applicationObject.accounts[0].isSpousal === true) ? SPOUSAL_RRSP_ACCOUNT : applicationObject.accounts[0].type;

        accountInformation.typesOfOptionsTradedBefore = clone(this.applicationService.appReferences.references.optionsTypeExperiences);
        accountInformation.typesOfOptionsTradedBefore.forEach(item => {
            item.checked = includes(applicationObject.accounts[0].accountFeatures.optionsTrading.optionTypeExperiences, item.code);
        });

        accountInformation.typesOfOptionsTradedNow = clone(this.applicationService.appReferences.references.optionTypes);
        accountInformation.typesOfOptionsTradedNow.forEach(item => {
            item.checked = includes(applicationObject.accounts[0].accountFeatures.optionsTrading.optionTypes, item.code);
        });

        if (applicationObject.accounts[0].promoCode !== null && applicationObject.accounts[0].promoCode !== '') {
            accountInformation.promocode = applicationObject.accounts[0].promoCode;
            accountInformation.tncChecked = applicationObject.accounts[0].tncChecked;
            accountInformation.tncDocName = applicationObject.accounts[0].tncDocName;
        } else {
            delete applicationObject.accounts[0].promoCode;
        }

        if (applicationObject.parties[0].creditBureauConsent !== null && applicationObject.parties[0].creditBureauConsent !== '') {
            accountInformation.creditBureautncChecked = applicationObject.parties[0].creditBureauConsent;
        } else {
            delete applicationObject.parties[0].creditBureauConsent;
        }
        if (!Utils.getIsBranchAssisted() && applicationObject.networkType) { // RSM
            this.formService.getPayload().setNetworkType(applicationObject.networkType);
            if (applicationObject.networkType === INTERNAL) {
                Utils.setIsBMONetwork(true);
                Utils.setAliveCheckNetwork(false);
                if (applicationObject.branchReferral) {
                    accountInformation.isBmoStaff = Utils.yesOrNoRadioMapper(applicationObject.branchReferral.isBMOReferral);
                    accountInformation.BmoStaffReferrer = applicationObject.branchReferral.designationCode;
                    accountInformation.isAdditionalReferral = Utils.yesOrNoRadioMapper(applicationObject.branchReferral.isAdditionalReferral);
                    accountInformation.EIN = applicationObject.branchReferral.ein;
                    accountInformation.referralCode = applicationObject.branchReferral.fpCode;
                    accountInformation.transit = applicationObject.branchReferral.transit;
                }
            }
        }
        return accountInformation;
    }

    setRRSPAlternateCitizenship(personalInformation) {

        let canadian_Citizen = personalInformation.citizenship == '100000' ? true : false;

        if (canadian_Citizen) { //If Canadian Citizen
            if (this.primaryApplicant.personal.identity.citizenships.length > 1) {
                personalInformation.otherCitizenshipOrTaxResidency = "Yes";

                /**Alternate citizenship */
                personalInformation.selectedCountries.push(
                    new SelectedCountry(personalInformation.countryIndex++, Utils.getCountriesList())
                );

                personalInformation.selectedCountries[0].code = this.primaryApplicant.personal.identity.citizenships[1];

                personalInformation.alternateCitizenship = personalInformation.selectedCountries[0].code;
                personalInformation.alternateCitizenshipLabelRRSP = Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[1]);

            } else {
                personalInformation.otherCitizenshipOrTaxResidency = "No";
            }

        } else {//If not Canadian Citizen

            if (this.primaryApplicant.personal.identity.citizenships.length > 0) {

                for (let i = 0; i < this.primaryApplicant.personal.identity.citizenships.length; i++) {
                    personalInformation.otherCitizenshipOrTaxResidency = "Yes";

                    /**Alternate citizenship */
                    personalInformation.selectedCountries.push(
                        new SelectedCountry(personalInformation.countryIndex++, Utils.getCountriesList())
                    );

                    personalInformation.selectedCountries[i].code = this.primaryApplicant.personal.identity.citizenships[i];
                    // personalInformation.alternateCitizenshipLabelRRSP = Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[1]);
                }

                this.personalInformation.citizenship = this.personalInformation.selectedCountries[0].code;
                this.personalInformation.citizenshipLabel = Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[0]);

                // if RRSP has two countries
                if (this.personalInformation.selectedCountries.length >= 2) {
                    this.personalInformation.alternateCitizenship = this.personalInformation.selectedCountries[1].code;
                    this.personalInformation.alternateCitizenshipLabelRRSP = Utils.getCountryLabel(this.primaryApplicant.personal.identity.citizenships[1]);
                }

            } else {
                personalInformation.otherCitizenshipOrTaxResidency = "No";
            }
        }

    }

    setTFSACashTaxResidencyAndAlternateCitizenship(personalInformation) {

        let citizenships = [];
        citizenships = this.primaryApplicant.personal.identity.citizenships;

        /**In case of TFSA/CASH */
        if (this.primaryApplicant.personal.hasTaxResidency || personalInformation.citizenship != '100000') {

            personalInformation.otherCitizenshipOrTaxResidency = "Yes";
            if (this.applicationService.getCaaTaxResidencyCertificateFlag() && (Utils.isCash() || Utils.isMargin())) {

                if (this.primaryApplicant.personal.hasCanadianTaxResidency && !this.applicationService.getCRAtaxresidencyEnabledFeatureFlag()) {
                    personalInformation.isCanadaTaxResidency = "Yes";
                }

                if (this.applicationService.getCRAtaxresidencyEnabledFeatureFlag()) {
                    const canadaTaxResidentList: any = this.primaryApplicant.personal.residencyForTax.find(x => x.country === COUNTRY_CODE.CANADA)

                    if (canadaTaxResidentList) {
                        personalInformation.isCanadaTaxResidency = "Yes";
                    }

                    if (this.primaryApplicant.personal.hasTaxResidency && (this.primaryApplicant.personal?.residencyForTax || []).some(res => res.country !== COUNTRY_CODE.CANADA && res.country !== COUNTRY_CODE.USA)) {
                        personalInformation.otherCitizenshipOrTaxResidency = "Yes"
                    }
                    else {
                        personalInformation.otherCitizenshipOrTaxResidency = "No"
                    }

                } else {
                    personalInformation.otherCitizenshipOrTaxResidency = this.primaryApplicant.personal.hasTaxResidency ? "Yes" : "No";
                }

            }
            // Do Reservse Mapping for US resident tax fields

            if (this.applicationService.getCaaTaxResidencyCertificateFlag() && (Utils.isCash() || Utils.isMargin())) {
                const taxArray = this.primaryApplicant.personal.residencyForTax.find(x => x.country === COUNTRY_CODE.USA);

                if (this.primaryApplicant.personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) > -1 || taxArray) {
                    personalInformation.usSINNumber = taxArray.taxIdentificationNumber;
                    personalInformation.usCitizenship = this.primaryApplicant.personal.hasUSTaxResidency ? "Yes" : "No"
                    personalInformation.usResidentCheckBox = this.primaryApplicant.personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) > -1;

                    personalInformation.usTaxResidentCheckBox = taxArray.country === COUNTRY_CODE.USA ? true : false;
                } else {
                    personalInformation.usCitizenship = this.primaryApplicant.personal.hasUSTaxResidency ? "Yes" : "No";
                }


                if (this.applicationService.getCRAtaxresidencyEnabledFeatureFlag() && taxArray) {
                    personalInformation.usTaxResidentCheckBox = taxArray.country == COUNTRY_CODE.USA ? true : false;
                    personalInformation.usSINNumber = taxArray.taxIdentificationNumber;
                }
            }

            /**ADD SELECTED COUNTRIES TO SELECTED COUNTRIES [] */
            /**Tax residency countries */
            for (let i = 1; i < this.primaryApplicant.personal.residencyForTax.length; i++) {
                let taxResident: any = this.primaryApplicant.personal.residencyForTax[i];
                personalInformation.selectedCountries.push(
                    new SelectedCountry(personalInformation.countryIndex++, this.applicationService.getCAUSExcludedContryList())
                );
                if (this.applicationService.getCRAtaxresidencyEnabledFeatureFlag()) {
                    if (this.primaryApplicant.personal.identity.citizenships.includes(taxResident.code)) {
                        personalInformation.selectedCountries[i - 1].isCitizen = true;
                    }
                }


                personalInformation.selectedCountries[i - 1].code = this.primaryApplicant.personal.residencyForTax[i].country;
                personalInformation.selectedCountries[i - 1].noTaxIdentificationNum = this.primaryApplicant.personal.residencyForTax[i].haveNoTaxIdentificationNumber;

                let haveNoTaxIdentificationNumber = personalInformation.selectedCountries[i - 1].noTaxIdentificationNum;

                personalInformation.selectedCountries[i - 1].isCitizen = false;

                personalInformation.selectedCountries[i - 1].isTaxDisabled = haveNoTaxIdentificationNumber ? false : true; //no clue
                personalInformation.selectedCountries[i - 1].isTinDisabled = haveNoTaxIdentificationNumber ? false : true; //definitely not sure

                personalInformation.selectedCountries[i - 1].otherReason = this.primaryApplicant.personal.residencyForTax[i].taxIdentificationNumberMissingReasonDescription;
                personalInformation.selectedCountries[i - 1].reasonSelected = this.primaryApplicant.personal.residencyForTax[i].taxIdentificationNumberMissingReason;

                //TAX RESIDENT VALUE SET
                if (!personalInformation.selectedCountries[i - 1].haveNoTaxIdentificationNumber) {
                    personalInformation.selectedCountries[i - 1].isTaxResident = true;
                } else {
                    personalInformation.selectedCountries[i - 1].isTaxResident = false;
                }

                /**Check if he's a citizen in general */
                if (Utils.isCitizen(citizenships, personalInformation.selectedCountries[i - 1].code)) {
                    personalInformation.selectedCountries[i - 1].isCitizen = true;

                    //Remove country from the list
                    let index = citizenships.indexOf(personalInformation.selectedCountries[i - 1].code);
                    citizenships.splice(index, 1);
                }

                /**SETUP DATA FOR taxIdentificationNumberMissingReason TO FILTER VALUE */
                personalInformation.taxIdentificationNumberMissingReason = this.applicationService.appReferences.references.taxIdentificationNumberMissingReason;
                SelectedCountry.prototype.taxIdentificationNumberMissingReason = personalInformation.taxIdentificationNumberMissingReason;

                if (personalInformation.selectedCountries[i - 1].code == '100001') {/**If USA, then SSN */
                    personalInformation.selectedCountries[i - 1].ssn = this.primaryApplicant.personal.residencyForTax[i].taxIdentificationNumber;
                    personalInformation.selectedCountries[i - 1].isTaxResident = true;

                } else {/**If not USA, then TIN */
                    personalInformation.selectedCountries[i - 1].tin = this.primaryApplicant.personal.residencyForTax[i].taxIdentificationNumber;
                }

            }

        } else {
            if (this.applicationService.getCaaTaxResidencyCertificateFlag() && (Utils.isCash() || Utils.isMargin())) {
                const taxArray = this.primaryApplicant.personal.residencyForTax.find(x => x.country === COUNTRY_CODE.USA)
                if (this.primaryApplicant.personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) > -1 || taxArray) {
                    personalInformation.usSINNumber = taxArray.taxIdentificationNumber;
                    personalInformation.usCitizenship = this.primaryApplicant.personal.hasUSTaxResidency ? "Yes" : "No"
                    personalInformation.usResidentCheckBox = this.primaryApplicant.personal.identity.citizenships.indexOf(COUNTRY_CODE.USA) > -1;

                    personalInformation.usTaxResidentCheckBox = taxArray.country === COUNTRY_CODE.USA ? true : false;;
                } else {
                    personalInformation.usCitizenship = this.primaryApplicant.personal.hasUSTaxResidency ? "Yes" : "No"

                    personalInformation.usTaxResidentCheckBox = this.primaryApplicant.personal.hasUSTaxResidency;
                    personalInformation.usResidentCheckBox = false;
                    if (personalInformation.usTaxResidentCheckBox) {

                        personalInformation.usSINNumber = taxArray.taxIdentificationNumber;
                    }
                }
            }
            if (this.primaryApplicant.personal.identity.citizenships.length > 1) {
                personalInformation.otherCitizenshipOrTaxResidency = "Yes";
            } else {
                personalInformation.otherCitizenshipOrTaxResidency = "No";
            }
            // Setting canadianTaxResidency and OtherCOuntryTaxResidency when getCaaTaxResidencyCertificateFlag is enabled
            if (this.applicationService.getCaaTaxResidencyCertificateFlag() && (Utils.isCash() || Utils.isMargin())) {
                if (this.primaryApplicant.personal.hasCanadianTaxResidency) {
                    personalInformation.isCanadaTaxResidency = "Yes";
                }
                else {
                    personalInformation.isCanadaTaxResidency = "No";
                }
                if (this.primaryApplicant.personal.hasTaxResidency) {
                    personalInformation.otherCitizenshipOrTaxResidency = "Yes"
                }
                else {
                    personalInformation.otherCitizenshipOrTaxResidency = "No"
                }
            }
        }

        /**Citizenship countries*/

        for (let i = 0; i < citizenships.length; i++) {

            if (this.primaryApplicant.personal.identity.citizenships[i] != '100001' && this.primaryApplicant.personal.identity.citizenships[i] != '100000') {

                if (!this.applicationService.getCRAtaxresidencyEnabledFeatureFlag() || (this.applicationService.getCRAtaxresidencyEnabledFeatureFlag() && !(Utils.isCash() || Utils.isMargin()))) {
                    personalInformation.selectedCountries.push(
                        new SelectedCountry(personalInformation.countryIndex++, this.applicationService.getCAUSExcludedContryList())
                    );

                let current_index = (personalInformation.selectedCountries.length - 1);

                personalInformation.selectedCountries[current_index].code = citizenships[i];
                personalInformation.selectedCountries[current_index].isCitizen = true;
             } else {
                personalInformation.selectedCitizenCountries.push(
                    new SelectedCitizenCountryList(personalInformation.citizenCountryIndex++, this.applicationService.getCAUSExcludedContryList()));

                    let current_index = (personalInformation.selectedCitizenCountries.length - 1);

                    personalInformation.selectedCitizenCountries[current_index].code = citizenships[i];

            }
        }

    }




        /**SET 'alternateCitizenshipLabelTFSACash' */
        personalInformation.alternateCitizenshipLabelTFSACash = personalInformation.selectedCountries
        .map(x => x.countryCitizenshipTaxResidencyString);

    //remove USA from selectedCoutries Array as we have moved US tax resident Quetion outside
    if(this.applicationService.getCaaTaxResidencyCertificateFlag() && (Utils.isCash() || Utils.isMargin())) {
    personalInformation.selectedCountries = personalInformation.selectedCountries.filter(x => x.code !== COUNTRY_CODE.USA);
}
    }



setCountriesFromReferences() {
    /**set countries */
    // this.applicationService.getReferences(this.lang);
    if (this.applicationService.appReferences.references.countries) {
        /**set countries list in utils */
        Utils.setCountriesList(this.applicationService.appReferences.references.countries);
    }
}

setAccountTypeOnResumeApplication(mockApplicationObject) {
    let accountType = mockApplicationObject.accounts[0].type;

    if (mockApplicationObject.accounts[0].type == ACCOUNT_TYPE.RRSP
        && mockApplicationObject.accounts[0].isSpousal === true) {
        accountType = ACCOUNT_CODES.SRRSP;
    }

    if (mockApplicationObject.accounts[0].type == ACCOUNT_TYPE.CASH
        && mockApplicationObject.accounts[0].subTypes.includes('2')) {
        accountType = ACCOUNT_CODES.MARGIN;
    }

    let title = Utils.getTitleOfSelectedAccount(accountType);

    this.accountTypeService.accountType.next(title);
}

setSetExpectationForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE PERSONAL INFO OBJECT */
    this.setExpectation = new SetExpectationObject();

    /**REVERSE MAPPING */
    const application: any = Utils.getApplicationObject();
    this.setExpectation = application.screenerQuestions;

    if (this.setExpectation.isBmoRightForMe !== null && this.setExpectation.isBmoRightForMe !== '') { /**PERSONAL INFO FORM IS FILLED */
        this.formService.forms.setExpectation.isCompleted = true;
        this.formService.forms.setExpectation.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('setExpectation', this.setExpectation);

        /**Payload */
        this.formService.getPayload().setScreenerQuestionsData(this.setExpectation);
        this.applicationService.populateSetExpectationForm();
    }
}

setAccountDetailsForm(mockApplicationObject) {

    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    this.accountDetail = new SelectAccount();

    /**REVERSE MAPPING */
    this.accountDetail = this.getAccountDetails(this.accountDetail, mockApplicationObject);
    if (this.accountDetail !== null) {
        this.formService.forms.accountDetail.isCompleted = true;
        this.formService.forms.accountDetail.isVisited = true;

        /**SET FORM DATA*/
        //this.formService.setFormData('accountDetail', this.accountDetail);
        this.formService.setFormData('selectAccount', this.accountDetail);

        /**payload */
        this.formService.getPayload().setAccountDetails(this.accountDetail);
        if (!Utils.getIsBranchAssisted() && mockApplicationObject.networkType === INTERNAL) { // RSM payload
            this.formService.getPayload().setBMOReferral(this.accountDetail);
        }
    }
}
setPersonalInfoForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE PERSONAL INFO OBJECT */
    this.personalInformation = new PersonalInformation();

    /**REVERSE MAPPING */
    this.personalInformation = this.getPersonalInformation(this.personalInformation);

    if (this.personalInformation !== null) { /**PERSONAL INFO FORM IS FILLED */
        this.formService.forms.personalInformation.isCompleted = true;
        this.formService.forms.personalInformation.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('personalInformation', this.personalInformation);

        /**Payload */
        this.formService.getPayload().setPersonalInformation(this.personalInformation);
    }
}
setContactInfoForm(mockApplicationObject) {


    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE CONTACT INFO OBJECT */
    this.contactInformation = new ContactInformation();


    /**REVERSE MAPPING */
    this.contactInformation = this.getContactInformation(this.contactInformation);


    if (this.contactInformation !== null) { /**CONTACT INFO FORM IS FILLED */
        this.formService.forms.contactInformation.isCompleted = true;
        this.formService.forms.contactInformation.isVisited = true;

        this.setTcpData();

        /**SET FORM DATA*/
        this.formService.setFormData('contactInformation', this.contactInformation);
        /**Payload */
        this.formService.getPayload().setContactInformation(this.contactInformation);
    }
}

setTcpData() {

    let tcpParty: any;
    (this.applicationService.getApplicationData().parties || []).forEach((party) => {

        if (this.primaryApplicant.trustedPartyRefId === party.partyRefId) {
            tcpParty = party;

        }
    });

    this.contactInformation.trustedContactPerson.isTrustedPerson = this.primaryApplicant.trustedContactPersonRequired;

    if (tcpParty) {
        //  const contactInformation = this.formService.getFormData('contactInformation');
        this.contactInformation.trustedContactPerson.firstname = tcpParty.personal.identity.legalName.firstName || '';
        this.contactInformation.trustedContactPerson.initial = tcpParty.personal.identity.legalName.middleName || '';
        this.contactInformation.trustedContactPerson.lastname = tcpParty.personal.identity.legalName.lastName || '';
        this.contactInformation.trustedContactPerson.country = tcpParty.personal.residence.primaryAddress.country || '';
        this.contactInformation.trustedContactPerson.province = tcpParty.personal.residence.primaryAddress.province || '';
        this.contactInformation.trustedContactPerson.postalCode = tcpParty.personal.residence.primaryAddress.postalCode || '';
        this.contactInformation.trustedContactPerson.streetNumberName = tcpParty.personal.residence.primaryAddress.streetAddress || '';
        this.contactInformation.trustedContactPerson.city = tcpParty.personal.residence.primaryAddress.city || '';
        this.contactInformation.trustedContactPerson.unit = tcpParty.personal.residence.primaryAddress.unitNumber || '';
        this.contactInformation.trustedContactPerson.phoneNumberHome = tcpParty.personal.residence.primaryPhone.phoneNumber || '';
        this.contactInformation.trustedContactPerson.phoneNumberOther = tcpParty.personal.residence.secondaryPhone.phoneNumber || '';
        this.contactInformation.trustedContactPerson.emailAddress = tcpParty.personal.identity.primaryEmailAddress || '';
        this.contactInformation.trustedContactPerson.tcpRelationShip = tcpParty.relationToAccHolder;
        this.contactInformation.trustedContactPerson.isInternationalNumber = tcpParty.personal.residence.primaryPhone.isInternationalNumber || tcpParty.personal.residence.secondaryPhone.isInternationalNumber;

    }


}

setEmploymentInfoForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE EMPLOYMENTINFO OBJECT */
    this.employmentInformation = new EmploymentInformation();

    /**REVERSE MAPPING */
    this.employmentInformation = this.getEmploymentInformation(this.employmentInformation);
    if (this.employmentInformation !== null) { /**EMPLOYMENTINFO INFO FORM IS FILLED */
        this.formService.forms.employmentInformation.isCompleted = true;
        this.formService.forms.employmentInformation.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('employmentInformation', this.employmentInformation);

        /**payload */
        this.formService.getPayload().setEmploymentInformation(this.employmentInformation, this.contactInformation);
    }

}
setFamilyInfoForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE Family INFO OBJECT */
    this.familyInformation = new FamilyInformation();

    /**REVERSE MAPPING */
    let spouseParty = this.getSpouse(mockApplicationObject);
    this.familyInformation = this.getFamilyInformation(this.familyInformation, spouseParty);

    if (this.familyInformation !== null) { /**Family INFO FORM IS FILLED */

        this.formService.forms.familyInformation.isCompleted = true;
        this.formService.forms.familyInformation.isVisited = true;

        /**SET FORM DATA*/

        this.formService.setFormData('familyInformation', this.familyInformation);

        /**payload */
        this.formService.getPayload().setFamilyInformation(this.familyInformation);

    }
}
setBeneficiaryInfoForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE Family INFO OBJECT */
    if (this.beneficiaryInformation.length == 0) {
        this.beneficiaryInformation.push(new BeneficiaryInformationObj());
    }
    this.getBeneficiaries(mockApplicationObject);

    /**REVERSE MAPPING */
    this.beneficiaryInformation = this.getBeneficiaryInformation(this.beneficiaryInformation);

    if (this.beneficiaryInformation !== null) { /**Family INFO FORM IS FILLED */

        this.formService.forms.beneficiaryInformation.isCompleted = true;
        this.formService.forms.beneficiaryInformation.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('beneficiaryInformation', this.beneficiaryInformation);

        /**payload */
        this.formService.getPayload().setBenificiaryInformation(this.beneficiaryInformation);

    }
}

setWithdrawalForm(mockApplicationObject) {
    let rifAccountIndex = 0;
    if (mockApplicationObject.accounts.length > 1) {
        rifAccountIndex = mockApplicationObject.accounts.findIndex(account => account.type == ACCOUNT_CODES.RIF);
    }
    this.withdrawalInformationObj = new WithdrawalInformationObj();
    if (Utils.noValueCheck(mockApplicationObject.accounts[rifAccountIndex].rifPayment.isSpouseAgeUsed)) {
        this.withdrawalInformationObj.isSpouseAgeUsed = mockApplicationObject.accounts[rifAccountIndex].rifPayment.isSpouseAgeUsed === true ? 'true' : 'false';
        if (mockApplicationObject.accounts[rifAccountIndex].rifPayment.isSpouseAgeUsed) {
            this.withdrawalInformationObj.spouseDateOfBirth = mockApplicationObject.parties[1].personal.identity.dateOfBirth;
        }
    }
    this.withdrawalInformationObj.frequency = mockApplicationObject.accounts[rifAccountIndex].rifPayment.frequency;
    this.withdrawalInformationObj.whenInMonth = mockApplicationObject.accounts[rifAccountIndex].rifPayment.whenInMonth;
    this.withdrawalInformationObj.startMonth = mockApplicationObject.accounts[rifAccountIndex].rifPayment.startMonth;
    this.withdrawalInformationObj.startYear = mockApplicationObject.accounts[rifAccountIndex].rifPayment.startYear;
    this.withdrawalInformationObj.greaterThanMinimumAmountType = mockApplicationObject.accounts[rifAccountIndex].rifPayment.greaterThanMinimumAmountType;
    this.withdrawalInformationObj.amountType = mockApplicationObject.accounts[rifAccountIndex].rifPayment.amountType;
    this.withdrawalInformationObj.amount = mockApplicationObject.accounts[rifAccountIndex].rifPayment.amount;
    this.withdrawalInformationObj.method = mockApplicationObject.accounts[rifAccountIndex].rifPayment.method;
    if (this.withdrawalInformationObj.method === RIF_PAYMENT_METHOD.NBMOEFT) {
        this.withdrawalInformationObj.institutionCode = mockApplicationObject.accounts[rifAccountIndex].outboundEftDetails.institutionCode;
        this.withdrawalInformationObj.institutionName = mockApplicationObject.accounts[rifAccountIndex].outboundEftDetails.institutionName;
    }
    this.withdrawalInformationObj.transitCode = mockApplicationObject.accounts[rifAccountIndex].outboundEftDetails.transitCode;
    if (Utils.noValueCheck(mockApplicationObject.accounts[rifAccountIndex].outboundEftDetails.isJointAccount)) {
        this.withdrawalInformationObj.isJointAccount = mockApplicationObject.accounts[rifAccountIndex].outboundEftDetails.isJointAccount === true ? 'true' : 'false'
    }
    if (this.withdrawalInformationObj.method === RIF_PAYMENT_METHOD.TFR) {
        this.withdrawalInformationObj.transferAccountNumber = mockApplicationObject.accounts[rifAccountIndex].rifPayment.transferAccountNumber;

    } else {
        this.withdrawalInformationObj.transferAccountNumber = mockApplicationObject.accounts[rifAccountIndex].outboundEftDetails.accountNumber;

    }
    this.withdrawalInformationObj.validAccount = mockApplicationObject.accounts[rifAccountIndex].outboundEftDetails.ownershipOfBankAccountVerified;
    //this.withdrawalInformationObj.spouseDateOfBirth = mockApplicationObject.accounts[0].contributor.dateOfBirth;
    if (this.withdrawalInformationObj !== null) {

        this.formService.forms.withdrawal.isCompleted = true;
        this.formService.forms.withdrawal.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('withdrawal', this.withdrawalInformationObj);

        /**payload */
        this.formService.getPayload().setWithdrawalInformation(this.withdrawalInformationObj);

    }
}

setFinancialInfoForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);
    /**INITIALIZE Financial INFO OBJECT */
    this.financialInformation = new FinancialInformation();

    /**REVERSE MAPPING */
    this.financialInformation = this.getFinancialInformation(this.financialInformation);

    if (this.financialInformation !== null) { /**Financial INFO FORM IS FILLED */

        this.formService.forms.financialInformation.isCompleted = true;
        this.formService.forms.financialInformation.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('financialInformation', this.financialInformation);

        /**payload */
        this.formService.getPayload().setFinancialInformation(this.financialInformation);

    }
}

setRiskAndReturn(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);
    /**INITIALIZE Risk And Return INFO OBJECT */
    this.riskAndReturn = new RiskAndReturn();

    /**REVERSE MAPPING */
    this.riskAndReturn = this.getRiskAndReturn(this.riskAndReturn);

    if (this.riskAndReturn !== null) { /**Financial INFO FORM IS FILLED */

        this.formService.forms.riskAndReturn.isCompleted = true;
        this.formService.forms.riskAndReturn.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('riskAndReturn', this.riskAndReturn);

        /**payload */
        this.formService.getPayload().setRiskAndReturn(this.riskAndReturn);

    }
}

setInvestmentObjectives(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);
    /**INITIALIZE Investment objectives INFO OBJECT */
    this.investmentObjectives = new AccountInvestmentObjectives();

    /**REVERSE MAPPING */
    this.investmentObjectives = this.getInvestmentObjectives(this.investmentObjectives);

    if (this.investmentObjectives !== null) { /**Financial INFO FORM IS FILLED */

        this.formService.forms.investmentObjectives.isCompleted = true;
        this.formService.forms.investmentObjectives.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('investmentObjectives', this.investmentObjectives);

        /**payload */
        this.formService.getPayload().setInvestmentObjectives(this.investmentObjectives);

    }
}

setInvestorProfile(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);
    /**INITIALIZE Investment objectives INFO OBJECT */
    this.investorProfile = new InvestorProfile();

    /**REVERSE MAPPING */
    this.investorProfile = this.getInvestorProfile(this.investorProfile);

    if (this.investorProfile !== null) { /**Financial INFO FORM IS FILLED */

        this.formService.forms.investorProfile.isCompleted = true;
        this.formService.forms.investorProfile.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('investorProfile', this.investorProfile);

        /**payload */
        this.formService.getPayload().setInvestorProfile(this.investorProfile);

    }
}

setConsentsInfoForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);
    /**INITIALIZE Consents OBJECT */
    this.consents = new Consents();

    /**REVERSE MAPPING */
    this.consents =
        this.getConsentsInformation(this.consents);

    if (this.consents !== null) { /**consents INFO FORM IS FILLED */
        this.formService.forms.consents.isCompleted = true;
        this.formService.forms.consents.isVisited = true;
        /**SET FORM DATA*/
        this.formService.setFormData('consents', this.consents); // For testing
        /**payload */
        this.formService.getPayload().setConsents(this.consents);
    }
}

setBMORelationshipInfoForm(mockApplicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    this.bmoRelationship = new BmoRelationship(); // create Empty BMO RelationShip obj
    if ((Utils.getIsBranchReferral() === true && Utils.isCash() === true) || (Utils.getIsBranchReferral() === false) || (Utils.getIsBranchAssisted() === true))
        this.bmoRelationship = this.getBMORelationshipInformation(this.bmoRelationship); // Get the valus to reverse map

    if (this.bmoRelationship !== null) {
        this.formService.forms.bmoRelationship.isCompleted = true;
        this.formService.forms.bmoRelationship.isVisited = true;
        /**SET FORM DATA*/
        // For All accounts SD users
        if (!Utils.isSubProductAD()) { // To Add Private Banking details
            // Mapping values to Payload
            this.bmoRelationship.isApplicantExistingPBClient = Utils.yesOrNoRadioMapper(this.primaryApplicant.bmoRelationship.isApplicantExistingPBClient);
            this.formService.getPayload().setPrivateBankingClient(this.bmoRelationship.isApplicantExistingPBClient);
        }
        this.formService.setFormData('bmoRelationship', this.bmoRelationship);
        /**payload */
        if ((Utils.getIsBranchReferral() === true && Utils.isCash() === true) || Utils.getIsBranchReferral() === false || Utils.getIsBranchAssisted() === true) {
            this.formService.getPayload().setBMORelationship(this.bmoRelationship);
        }

    }

}

setRegulatoryDisclosureInfoForm(mockApplicationObject) {
    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE RegulatoryDisclosures INFO OBJECT */
    this.regulatoryInformation = new RegulatoryDisclosure();

    /**REVERSE MAPPING */
    this.regulatoryInformation = this.getRegulatoryDisclosure(this.regulatoryInformation);

    if (this.regulatoryInformation !== null) { /**EMPLOYMENTINFO INFO FORM IS FILLED */
        this.formService.forms.regulatoryDisclosures.isCompleted = true;
        this.formService.forms.regulatoryDisclosures.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('regulatoryDisclosures', this.regulatoryInformation);

        /**payload */
        this.formService.getPayload().setRegulatoryDisclosure(this.regulatoryInformation);
    }
}

setAccountPasswordInfoForm(mockApplicationObject) {
    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(mockApplicationObject);

    /**INITIALIZE AccountPassword INFO OBJECT */
    this.accountPasswordInformation = new AccountPassword();

    /**REVERSE MAPPING */
    this.accountPasswordInformation =
        this.getAccountPassword(this.accountPasswordInformation);

    if (this.accountPasswordInformation !== null) { /**AccountPassword INFO FORM IS FILLED */
        this.formService.forms.accountPassword.isCompleted = true;
        this.formService.forms.accountPassword.isVisited = true;

        /**SET FORM DATA*/
        this.formService.setFormData('accountPassword', this.accountPasswordInformation);

        /**payload */
        this.formService.getPayload().setAccountPassword(this.accountPasswordInformation);
    }
}

setInvestmentKnowledgeForm(applicationObject) {

    /**SET ACCOUNT TYPE */
    this.setAccountTypeOnResumeApplication(applicationObject);
    /**INITIALIZE Financial INFO OBJECT */
    this.investmentKnowledge = new InvestmentKnowledge();

    /**REVERSE MAPPING */
    this.investmentKnowledge = this.getInvestmentKnowledge(this.investmentKnowledge);

    if (this.investmentKnowledge !== null) { /**Financial INFO FORM IS FILLED */

        this.formService.forms.investmentKnowledge.isCompleted = true;
        this.formService.forms.investmentKnowledge.isVisited = true;

        /**SET FORM DATA*/

        this.formService.setFormData('investmentKnowledge', this.investmentKnowledge);

        /**payload */
        this.formService.getPayload().setInvestmentKnowledge(this.investmentKnowledge);


    }
}

onResumeApplicationPrepopulate() {

    const application: any = Utils.getApplicationObject();

    Utils.setApplicationInflightStatus(application.inflightMigrationFlag);

    if (Utils.getIsBranchReferral()) {
        this.prefillService.branchReferralPrefill();
    }

    // Stubbing for T&C Page
    if (Utils.getIsInSession() && application.status == APPLICATION_STATUS.DocumentCollection) { // If Status is Document Collection

        this.applicationService.getPackageSignInStatus(application.applicationId).subscribe(
            (response: any) => {
                if (response.status === SUCCESS_RESPONSE) {
                    Utils.setdocumentPackageStatus(response.body[0]);

                    if (Utils.getdocumentPackageStatus() === DOCUMENT_STATUS.ESignPending) { //IF ESIGN IS PENDING
                        Utils.setNavigationDissabled(true);
                        this.applicationService.applicationNumber = application.applicationNumber;
                        this.markAllPagesComplete();
                        this.setFormDataForEsign();
                        const primaryAccount = this.getPrimaryAccount(application);
                        if (Utils.nullCheck(primaryAccount.accountFeatures)) {
                            Utils.callSelectAccount = true;
                            this.setAccountDetailsForm(application);
                        }
                        // this.applicationService.appResponse.applicationId=application.
                        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.terms_conditions);
                        this.applicationService.setNavigationDisabled(true); // Disable Navigation -> Event Emitted
                    }
                    else if (Utils.getdocumentPackageStatus() === DOCUMENT_STATUS.Completed) {
                        // IF ESIGN IS COMPLETED
                        this.applicationService.applicationNumber = application.applicationNumber;
                        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.next_steps);
                    }

                }
            },
            error => {
                // console.log("error", error);
            });
    }
    else if (Utils.getIsInSession() && application.status == APPLICATION_STATUS.Completed) { // If Status is Completed
        this.applicationService.applicationNumber = application.applicationNumber;
        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.next_steps);
    }
    else {

        if (Utils.getIsInSession()) {

            const application: any = Utils.getApplicationObject();

            const primaryApplicant = this.getPrimaryApplicant(application);

            const primaryAccount = this.getPrimaryAccount(application);

            /**Set party ref id */
            Utils.setPartyRefId(primaryApplicant.partyRefId);
            this.formService.setPartyRefIdInPayload(primaryApplicant.partyRefId);

            /**SET COUNTRIES */
            this.setCountriesFromReferences();

            // set beneficiary condition for navigation
            if (((application.accounts[0].type == ACCOUNT_TYPE.TFSA) ||
                (application.accounts[0].type == ACCOUNT_TYPE.RRSP) ||
                (application.accounts[0].type == ACCOUNT_TYPE.RIF)) &&
                application.parties[0].personal.residence.primaryAddress.province !== QUEBEC && (Utils.getIsBranchAssisted() || Utils.hasBeneficiaryDetails())) {

                Utils.setBeneficiaryPageCondition(true);
                this.applicationService.setBeneficiaryNavigationDisabled(false); // Enable Navigation -> Event Emitted
            } else {
                Utils.setBeneficiaryPageCondition(false);
                this.applicationService.setBeneficiaryNavigationDisabled(true); // Enable Navigation -> Event Emitted
            }

            // set beneficiary condition for navigation
            if (application.accounts[0].type == ACCOUNT_TYPE.RIF) {
                Utils.setWithdrawalPageCondition(true);
                this.applicationService.setWithdrawalNavigationDisabled(false);
            } else {
                Utils.setWithdrawalPageCondition(false);
                this.applicationService.setWithdrawalNavigationDisabled(true);
            }


            // The below information needs to be passed back, else it will throw error during
            // document_package call
            this.formService.getPayload().applicationId = application.applicationId;
            this.formService.getPayload().applicationNumber = application.applicationNumber;
            this.formService.getPayload().applicationSubProduct = application.applicationSubProduct;

            if (application.accounts && application.accounts.length) {
                this.formService.getPayload().accounts[0].type = application.accounts[0].type;
                this.formService.getPayload().accounts[0].subTypes = application.accounts[0].subTypes;
            }
            // Set setExpectation and  accountSelection completed
            this.setExpectation = new SetExpectationObject();
            this.setExpectation = Utils.getApplicationObject()?.screenerQuestions;
            if (application.pageStatus.thingsYouShouldKnow && this.setExpectation.isBmoRightForMe !== null && this.setExpectation.isBmoRightForMe !== '') {
                this.formService.forms.setExpectation.isCompleted = true;
                this.formService.forms.setExpectation.isVisited = true;
            }
            this.formService.forms.selectAccount.isCompleted = true;
            this.formService.forms.selectAccount.isVisited = true;

            /**REVERSE MAPPING FOR Account Details PAGE */
            if (Utils.nullCheck(primaryAccount.accountFeatures)) {
                Utils.callSelectAccount = true;
                this.setAccountDetailsForm(application);
            }

            if (application.pageStatus === null) {
                this.routeTo(application);
            } else {
                /**REVERSE MAPPING FOR THINKS YOU SHOULD KNOW PAGE */
                if (application.pageStatus.thingsYouShouldKnow) {
                    this.setSetExpectationForm(application);
                }

                /**REVERSE MAPPING FOR PERSOANL INFO PAGE */
                if (application.pageStatus.personalInformation || (Utils.getIsBranchAssisted() && application.inflightMigrationFlag) || (this.applicationService.getCaaTaxResidencyCertificateFlag() && application.inflightMigrationFlag)) {

                    this.setPersonalInfoForm(application);
                }

                /**REVERSE MAPPING FOR CONTACT INFO PAGE */
                if (application.pageStatus.contactInformation) {
                    this.setContactInfoForm(application);
                }

                /**REVERSE MAPPING FOR Employment INFO PAGE */
                if (application.pageStatus.employmentInformation) {
                    this.setEmploymentInfoForm(application);
                }

                /**REVERSE MAPPING FOR Family INFO PAGE */
                if (application.pageStatus.familyInformation) {
                    this.setFamilyInfoForm(application);
                }

                /**REVERSE MAPPING FOR Beneficiary INFO PAGE */
                if (Utils.getBeneficiaryPageCondition() && application.pageStatus.beneficiaryInformation) {
                    this.setBeneficiaryInfoForm(application);
                }

                /**REVERSE MAPPING FOR Withdrawal INFO PAGE */
                if (Utils.getWithdrawalPageCondition() && application.pageStatus.withdrawal) {
                    this.setWithdrawalForm(application);
                }

                /**REVERSE MAPPING FOR Financial INFO PAGE */
                if (application.pageStatus.financialInformation) {
                    this.setFinancialInfoForm(application);
                }

                /**REVERSE MAPPING FOR Investment Knowledge  PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.investmentKnowledge) {

                    this.setInvestmentKnowledgeForm(application);
                }

                /**REVERSE MAPPING FOR RISK AND RETURN PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.riskAndReturn) {
                    this.setRiskAndReturn(application);
                }

                /**REVERSE MAPPING FOR INVESTMENT OBJECTIVES PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.investmentObjective) {
                    // this.setInvestmentObjectives(application);
                    this.setRiskAndReturn(application);
                }

                /**REVERSE MAPPING FOR INVESTOR PROFILE PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.investorProfile) {
                    this.setInvestorProfile(application);
                }

                /**REVERSE MAPPING FOR Regulatory Disclosure INFO PAGE */
                if (application.pageStatus.regulatoryDisclosures) {
                    this.setRegulatoryDisclosureInfoForm(application);
                }

                /**REVERSE MAPPING FOR ConsentsInformation INFO PAGE */
                if (application.pageStatus.consents) {
                    this.setConsentsInfoForm(application);
                }

                /**REVERSE MAPPING FOR BmoRelationship INFO PAGE */

                if (((Utils.getIsBranchReferral() === true && (Utils.isCash() === true || Utils.isMargin() === true)) || (Utils.getIsBranchReferral() === false) || (Utils.getIsBranchReferral() === true && !Utils.isSubProductAD)) &&
                    application.pageStatus.bmoRelationship) {
                    this.setBMORelationshipInfoForm(application);
                }
                /**For BranchAssisted default  BMO relationship to True */
                if (Utils.getIsBranchAssisted() === true) {
                    this.formService.getPayload().setBranchBMORelationship(true);
                    if (Utils.isCash() !== true && Utils.isMargin() !== true) {
                        const isPrivateBankingClient = application.parties[0].bmoRelationship.isApplicantExistingPBClient && application.parties[0].bmoRelationship.isApplicantExistingPBClient === true ? 'Yes' : 'No'

                        this.formService.getPayload().setPrivateBankingClient(isPrivateBankingClient)
                        if (application.pageStatus.bmoRelationship) {
                            this.formService.forms.bmoRelationship.isCompleted = true;
                            this.formService.forms.bmoRelationship.isVisited = true;
                            // this.formService.forms.bmoRelationship.data.
                            this.formService.setFormData('bmoRelationship', application.parties[0].bmoRelationship);

                        }
                    }

                }

                /**REVERSE MAPPING FOR AccountPassword INFO PAGE */
                if (application.pageStatus.accountPassword) {
                    this.setAccountPasswordInfoForm(application);
                }

                // ROUTING
                const self = this;
                setTimeout(function () {
                    self.routeTo(application);
                });

            }
        }
    }
}

onResumeMultiAccountApplicationPrepopulate() {

    const application: any = Utils.getApplicationObject();



    if (Utils.getIsBranchReferral()) {
        this.prefillService.branchReferralPrefill();
    }

    // Stubbing for T&C Page
    if (Utils.getIsInSession() && application.status == APPLICATION_STATUS.DocumentCollection) { // If Status is Document Collection
        const primaryAccount = this.getPrimaryAccount(application);
            if (Utils.nullCheck(primaryAccount.accountFeatures) && !Utils.getIsBranchReferral() ) {
            Utils.callSelectAccount = true;
            this.setMultiAccountDetailsForm(application);
        }
        this.applicationService.getPackageSignInStatus(application.applicationId).subscribe(
            (response: any) => {
                if (response.status === SUCCESS_RESPONSE) {

                    Utils.setdocumentPackageStatus(response.body[0]);

                    if (Utils.getdocumentPackageStatus() === DOCUMENT_STATUS.ESignPending) { //IF ESIGN IS PENDING
                        Utils.setNavigationDissabled(true);
                        this.applicationService.applicationNumber = application.applicationNumber;
                        this.markAllPagesComplete();
                        this.setFormDataForEsign();
                        const primaryAccount = this.getPrimaryAccount(application);
                        if (Utils.nullCheck(primaryAccount.accountFeatures)) {
                            Utils.callSelectAccount = true;
                            this.setMultiAccountDetailsForm(application);
                        }
                        // this.applicationService.appResponse.applicationId=application.
                        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.terms_conditions);
                        this.applicationService.setNavigationDisabled(true); // Disable Navigation -> Event Emitted
                    }
                    else if (Utils.getdocumentPackageStatus() === DOCUMENT_STATUS.Completed) {
                        // IF ESIGN IS COMPLETED
                        this.applicationService.applicationNumber = application.applicationNumber;
                        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.next_steps);
                    }

                }
            },
            error => {
                // console.log("error", error);
            });
    }
    else if (Utils.getIsInSession() && application.status == APPLICATION_STATUS.Completed) { // If Status is Completed
        this.applicationService.applicationNumber = application.applicationNumber;
        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.next_steps);
    }
    else {

        if (Utils.getIsInSession()) {

            const application: any = Utils.getApplicationObject();

            const primaryApplicant = this.getPrimaryApplicant(application);

            const primaryAccount = this.getPrimaryAccount(application);

            /**Set party ref id */
            Utils.setPartyRefId(primaryApplicant.partyRefId);
            this.formService.setPartyRefIdInPayload(primaryApplicant.partyRefId);

            /**SET COUNTRIES */
            this.setCountriesFromReferences();
            let rifAccount: any = (application.accounts || []).filter(account => account.type == ACCOUNT_TYPE.RIF);
            let TFSAAccount: any = (application.accounts || []).filter(account => account.type == ACCOUNT_TYPE.TFSA);
            let RRSPAccount: any = (application.accounts || []).filter(account => account.type == ACCOUNT_TYPE.RRSP);

            // set beneficiary condition for navigation
            if (((TFSAAccount && TFSAAccount.length > 0) ||
                (RRSPAccount && RRSPAccount.length > 0) ||
                (rifAccount && rifAccount.length > 0)) &&
                (application.parties[0].personal.residence.primaryAddress.province !== QUEBEC) &&
                (Utils.getIsBranchAssisted() || Utils.hasBeneficiaryDetails())) {
                if (!Utils.isBranchSDMultiAccountFlow()) {
                    Utils.setBeneficiaryPageCondition(true);
                    this.applicationService.setBeneficiaryNavigationDisabled(false); // Enable Navigation -> Event Emitted
                }
            } else {
                Utils.setBeneficiaryPageCondition(false);
                this.applicationService.setBeneficiaryNavigationDisabled(true); // Enable Navigation -> Event Emitted
            }

            // set beneficiary condition for navigation


            if (rifAccount && rifAccount.length > 0) {
                Utils.setWithdrawalPageCondition(true);
                this.applicationService.setWithdrawalNavigationDisabled(false);
            } else {
                Utils.setWithdrawalPageCondition(false);
                this.applicationService.setWithdrawalNavigationDisabled(true);
            }


            // The below information needs to be passed back, else it will throw error during
            // document_package call
            this.formService.getPayload().applicationId = application.applicationId;
            this.formService.getPayload().applicationNumber = application.applicationNumber;
            this.formService.getPayload().applicationSubProduct = application.applicationSubProduct;

            if (application.accounts && application.accounts.length) {

                this.formService.getPayload().accounts[0].subTypes = application.accounts[0].subTypes;
            }
            // Set setExpectation and  accountSelection completed
            this.setExpectation = new SetExpectationObject();
            this.setExpectation = Utils.getApplicationObject()?.screenerQuestions;
            if (application.pageStatus.thingsYouShouldKnow && this.setExpectation.isBmoRightForMe !== null && this.setExpectation.isBmoRightForMe !== '') {
                this.formService.forms.setExpectation.isCompleted = true;
                this.formService.forms.setExpectation.isVisited = true;
            }
            this.formService.forms.selectAccount.isCompleted = true;
            this.formService.forms.selectAccount.isVisited = true;

            /**REVERSE MAPPING FOR Account Details PAGE */
            if (Utils.nullCheck(primaryAccount.accountFeatures)) {
                Utils.callSelectAccount = true;
                this.setMultiAccountDetailsForm(application);
            }

            if (application.pageStatus === null) {
                this.routeTo(application);
            } else {
                /**REVERSE MAPPING FOR THINKS YOU SHOULD KNOW PAGE */
                if (application.pageStatus.thingsYouShouldKnow) {
                    this.setSetExpectationForm(application);
                }

                /**REVERSE MAPPING FOR PERSOANL INFO PAGE */
                if (application.pageStatus.personalInformation || (Utils.getIsBranchAssisted() && application.inflightMigrationFlag) || (this.applicationService.getCaaTaxResidencyCertificateFlag() && application.inflightMigrationFlag)) {

                    this.setPersonalInfoForm(application);
                }

                /**REVERSE MAPPING FOR CONTACT INFO PAGE */
                if (application.pageStatus.contactInformation) {
                    this.setContactInfoForm(application);
                }

                /**REVERSE MAPPING FOR Employment INFO PAGE */
                if (application.pageStatus.employmentInformation) {
                    this.setEmploymentInfoForm(application);
                }

                /**REVERSE MAPPING FOR Family INFO PAGE */
                if (application.pageStatus.familyInformation) {
                    this.setFamilyInfoForm(application);
                }

                /**REVERSE MAPPING FOR Beneficiary INFO PAGE */
                if (Utils.getBeneficiaryPageCondition() && application.pageStatus.beneficiaryInformation) {
                    this.setBeneficiaryInfoForm(application);
                }

                /**REVERSE MAPPING FOR Withdrawal INFO PAGE */
                if (Utils.getWithdrawalPageCondition() && application.pageStatus.withdrawal) {
                    this.setWithdrawalForm(application);
                }

                /**REVERSE MAPPING FOR Financial INFO PAGE */
                if (application.pageStatus.financialInformation) {
                    this.setFinancialInfoForm(application);
                }

                /**REVERSE MAPPING FOR Investment Knowledge  PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.investmentKnowledge) {

                    this.setInvestmentKnowledgeForm(application);
                }

                /**REVERSE MAPPING FOR RISK AND RETURN PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.riskAndReturn) {
                    this.setRiskAndReturn(application);
                }

                /**REVERSE MAPPING FOR INVESTMENT OBJECTIVES PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.investmentObjective) {
                    // this.setInvestmentObjectives(application);
                    this.setRiskAndReturn(application);
                }

                /**REVERSE MAPPING FOR INVESTOR PROFILE PAGE */
                if (Utils.isSubProductAD() && application.pageStatus.investorProfile) {
                    this.setInvestorProfile(application);
                }

                /**REVERSE MAPPING FOR Regulatory Disclosure INFO PAGE */
                if (application.pageStatus.regulatoryDisclosures) {
                    this.setRegulatoryDisclosureInfoForm(application);
                }

                /**REVERSE MAPPING FOR ConsentsInformation INFO PAGE */
                if (application.pageStatus.consents) {
                    this.setConsentsInfoForm(application);
                }

                /**REVERSE MAPPING FOR BmoRelationship INFO PAGE */

                if (((Utils.getIsBranchReferral() === true && (Utils.isCash() === true || Utils.isMargin() === true)) || (Utils.getIsBranchReferral() === false) || (Utils.getIsBranchReferral() === true && !Utils.isSubProductAD)) &&
                    application.pageStatus.bmoRelationship) {
                    this.setBMORelationshipInfoForm(application);
                }
                /**For BranchAssisted default  BMO relationship to True */
                if (Utils.getIsBranchAssisted() === true) {
                    this.formService.getPayload().setBranchBMORelationship(true);
                    if (Utils.isCash() !== true && Utils.isMargin() !== true) {
                        const isPrivateBankingClient = application.parties[0].bmoRelationship.isApplicantExistingPBClient && application.parties[0].bmoRelationship.isApplicantExistingPBClient === true ? 'Yes' : 'No'

                        this.formService.getPayload().setPrivateBankingClient(isPrivateBankingClient)
                        if (application.pageStatus.bmoRelationship) {
                            this.formService.forms.bmoRelationship.isCompleted = true;
                            this.formService.forms.bmoRelationship.isVisited = true;
                            // this.formService.forms.bmoRelationship.data.
                            this.formService.setFormData('bmoRelationship', application.parties[0].bmoRelationship);

                        }
                    }

                }

                /**REVERSE MAPPING FOR AccountPassword INFO PAGE */
                if (application.pageStatus.accountPassword) {
                    this.setAccountPasswordInfoForm(application);
                }

                // ROUTING
                const self = this;
                setTimeout(function () {
                    self.routeTo(application);
                });

            }
        }
    }
}
setMultiAccountDetailsForm(mockApplicationObject) {

    this.setMultiAccountTypeOnResumeApplication(mockApplicationObject);

    this.accountDetail = new SelectAccount();

    /**REVERSE MAPPING */
    this.accountDetail = this.getMultiAccountDetails(this.accountDetail, mockApplicationObject);
    if (this.accountDetail !== null) {
        this.formService.forms.accountDetail.isCompleted = true;
        this.formService.forms.accountDetail.isVisited = true;
        //set the select account FormData
        this.formService.setFormData('selectAccount', this.accountDetail);
        //get the payload ready for next save call
        this.formService.getPayload().setMultiAccountDetails(this.accountDetail);
        if (!Utils.getIsBranchAssisted() && mockApplicationObject.networkType === INTERNAL) { // RSM payload
            this.formService.getPayload().setBMOReferral(this.accountDetail);
        }
    }
}
setMultiAccountTypeOnResumeApplication(mockApplicationObject) {
    let selectedAccountTypes = [];
    for (let i in mockApplicationObject.accounts) {
        selectedAccountTypes[i] = mockApplicationObject.accounts[i].type;
        if (mockApplicationObject.accounts[i].type == ACCOUNT_TYPE.RRSP && mockApplicationObject.accounts[i].isSpousal === true) {
            selectedAccountTypes[i] = ACCOUNT_TYPE.SRRSP;
        } else if (mockApplicationObject.accounts[i].type == ACCOUNT_TYPE.CASH && mockApplicationObject.accounts[i].subTypes.includes('2')) {
            selectedAccountTypes[i] = '100544-MARGIN';
        }
    }
    this.accountTypeService.accountType.next(selectedAccountTypes);
}
getMultiAccountDetails(accountInformation, applicationObject) {
    //accountInformation.needOptionsTrading = Utils.yesOrNoRadioMapper(applicationObject.accounts[0].accountFeatures.isOptionTrading);
    accountInformation.optionsTradingForAllAccounts = applicationObject.optionsTradingForAllAccounts

    accountInformation.selectedAccount = null;
    let isOptionTrade: boolean = false;
    let optionTypesAccountIndex: any = 0;
    for (let i in applicationObject.accounts) {
        accountInformation.selectedAccountTypes[i] = applicationObject.accounts[i].type;
        //convert spousal and margin accounts types to show the selected account while resuming
        if (applicationObject.accounts[i].type == ACCOUNT_TYPE.RRSP && applicationObject.accounts[i].isSpousal === true) {
            accountInformation.selectedAccountTypes[i] = ACCOUNT_TYPE.SRRSP;
        }
        else if (applicationObject.accounts[i].type == ACCOUNT_TYPE.CASH && applicationObject.accounts[i].subTypes.includes('2')) {
            accountInformation.selectedAccountTypes[i] = '100544-MARGIN';
            accountInformation.needShortSelling = Utils.yesOrNoRadioMapper(applicationObject.accounts[i].accountFeatures.isShortSelling);
            if (applicationObject.accounts[i].accountFeatures.isOptionTrading) {
                optionTypesAccountIndex = i; // margin account index - spread option type only available for margin
            }
        }
        if (!isOptionTrade && applicationObject.accounts[i].accountFeatures.isOptionTrading) {
            isOptionTrade = true;
            optionTypesAccountIndex = i;
        }
        accountInformation.optionTradingMulti[i] = Utils.yesOrNoRadioMapper(applicationObject.accounts[i].accountFeatures.isOptionTrading);
    }

    accountInformation.needOptionsTrading = Utils.yesOrNoRadioMapper(isOptionTrade);
    accountInformation.yearsOfExperience = applicationObject.accounts[optionTypesAccountIndex].accountFeatures.optionsTrading.experienceInYears;
    accountInformation.tradingKnowledgeLevel = applicationObject.accounts[optionTypesAccountIndex].accountFeatures.optionsTrading.knowledge;
    this.applicationService.checkHasTradingKnowledge(applicationObject.accounts[optionTypesAccountIndex].accountFeatures.optionsTrading.knowledge);
    accountInformation.typesOfOptionsTradedBefore = clone(this.applicationService.appReferences.references.optionsTypeExperiences);
    accountInformation.typesOfOptionsTradedBefore.forEach(item => {
        item.checked = includes(applicationObject.accounts[0].accountFeatures.optionsTrading.optionTypeExperiences, item.code);
    });

    accountInformation.typesOfOptionsTradedNow = clone(this.applicationService.appReferences.references.optionTypes);
    accountInformation.typesOfOptionsTradedNow.forEach(item => {
        item.checked = includes(applicationObject.accounts[optionTypesAccountIndex].accountFeatures.optionsTrading.optionTypes, item.code);
    });

    if (applicationObject.accounts[0].promoCode !== null && applicationObject.accounts[0].promoCode !== '') {
        accountInformation.promocode = applicationObject.accounts[0].promoCode;
        accountInformation.tncChecked = applicationObject.accounts[0].tncChecked;
        accountInformation.tncDocName = applicationObject.accounts[0].tncDocName;
    } else {
        delete applicationObject.accounts[0].promoCode;
    }

    if (applicationObject.parties[0].creditBureauConsent !== null && applicationObject.parties[0].creditBureauConsent !== '') {
        accountInformation.creditBureautncChecked = applicationObject.parties[0].creditBureauConsent;
    } else {
        delete applicationObject.parties[0].creditBureauConsent;
    }
    if (!Utils.getIsBranchAssisted() && applicationObject.networkType) { // RSM
        this.formService.getPayload().setNetworkType(applicationObject.networkType);
        if (applicationObject.networkType === INTERNAL) {
            Utils.setIsBMONetwork(true);
            Utils.setAliveCheckNetwork(false);
            if (applicationObject.branchReferral) {
                accountInformation.isBmoStaff = Utils.yesOrNoRadioMapper(applicationObject.branchReferral.isBMOReferral);
                accountInformation.BmoStaffReferrer = applicationObject.branchReferral.designationCode;
                accountInformation.isAdditionalReferral = Utils.yesOrNoRadioMapper(applicationObject.branchReferral.isAdditionalReferral);
                accountInformation.EIN = applicationObject.branchReferral.ein;
                accountInformation.referralCode = applicationObject.branchReferral.fpCode;
                accountInformation.transit = applicationObject.branchReferral.transit;
            }
        }
    }
    return accountInformation;
}
routeTo(application) {
    if (application.pageStatus === null) {
        this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.personal_information);
    } else {
        if (!application.pageStatus.thingsYouShouldKnow && application.pageStatus.thingsYouShouldKnow !== null && application.pageStatus.thingsYouShouldKnow !== undefined) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.set_expectation);

        } else if (!application.pageStatus.personalInformation) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.personal_information);

        } else if (!application.pageStatus.contactInformation) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.contact_information);

        } else if (!application.pageStatus.employmentInformation) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.employment_information);

        } else if (!application.pageStatus.familyInformation) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.family_information);

        } else if (Utils.getBeneficiaryPageCondition() && !application.pageStatus.beneficiaryInformation) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.beneficiary);

        } else if (Utils.getWithdrawalPageCondition() && !application.pageStatus.withdrawal) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.withdrawal);

        } else if (!application.pageStatus.financialInformation) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.financial_details);

        } else if (Utils.isSubProductAD() && !application.pageStatus.investmentKnowledge) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.investment_knowledge);

        } else if (Utils.isSubProductAD() && !application.pageStatus.investmentObjective) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.riskAndReturn);

        } else if (Utils.isSubProductAD() && !application.pageStatus.investorProfile) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.investor_profile);

        } else if (!application.pageStatus.regulatoryDisclosures) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.regulatory_disclosures);

        } else if (!application.pageStatus.consents) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.consents);

        } else if (((Utils.getIsBranchReferral() === true && Utils.isCash() === true && Utils.isSubProductAD()) ||
            (Utils.getIsBranchReferral() === true && !Utils.isSubProductAD()) || (Utils.getIsBranchReferral() === false))
            && !application.pageStatus.bmoRelationship && Utils.getIsOLBUser() === false && !Utils.getIsJourneyUser()) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.bmo_relationship);

        }
        else if(application.pageStatus.consents && this.isJourneyRegisteredAcc()) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.review);
        }
        else if (!application.pageStatus.accountPassword && !Utils.resumeApplicationDowngrade) {
            this.applicationService.saveAndcontinue(this.selectAccount, Utils.getIsOLBUser() || this.isJourneyNonRegisteredAcc() ? APP_ROUTES.account_access : APP_ROUTES.account_password);

        }
        else if (Utils.resumeApplicationDowngrade) {
            this.applicationService.saveAndcontinue(this.selectAccount, APP_ROUTES.application_approval_downgrade);
        }else { }

    }
}

private isJourneyNonRegisteredAcc() {
    return Utils.getIsJourneyUser() && (Utils.isCash() || Utils.isMargin());
}
private isJourneyRegisteredAcc() {
    return Utils.getIsJourneyUser() && !(Utils.isCash() || Utils.isMargin());
}

markAllPagesComplete() {
    this.formService.forms.setExpectation.isCompleted = true;
    this.formService.forms.setExpectation.isVisited = true;
    this.formService.forms.selectAccount.isCompleted = true;
    this.formService.forms.selectAccount.isVisited = true;
    this.formService.forms.personalInformation.isCompleted = true;
    this.formService.forms.personalInformation.isVisited = true;
    this.formService.forms.contactInformation.isCompleted = true;
    this.formService.forms.contactInformation.isVisited = true;
    this.formService.forms.employmentInformation.isCompleted = true;
    this.formService.forms.employmentInformation.isVisited = true;
    this.formService.forms.familyInformation.isCompleted = true;
    this.formService.forms.familyInformation.isVisited = true;
    this.formService.forms.beneficiaryInformation.isCompleted = true;
    this.formService.forms.beneficiaryInformation.isVisited = true;
    this.formService.forms.financialInformation.isCompleted = true;
    this.formService.forms.financialInformation.isVisited = true;
    this.formService.forms.investmentKnowledge.isCompleted = true;
    this.formService.forms.investmentKnowledge.isVisited = true;
    this.formService.forms.investmentObjectives.isCompleted = true;
    this.formService.forms.investmentObjectives.isVisited = true;
    this.formService.forms.riskAndReturn.isCompleted = true;
    this.formService.forms.riskAndReturn.isVisited = true;
    this.formService.forms.investorProfile.isVisited = true;
    this.formService.forms.investorProfile.isCompleted = true;
    this.formService.forms.regulatoryDisclosures.isVisited = true;
    this.formService.forms.regulatoryDisclosures.isCompleted = true;
    // commenting as its a duplicate line of code
    // this.formService.forms.regulatoryDisclosures.isVisited = true;
    this.formService.forms.consents.isCompleted = true;
    this.formService.forms.consents.isVisited = true;
    this.formService.forms.bmoRelationship.isCompleted = true;
    this.formService.forms.bmoRelationship.isVisited = true;
    this.formService.forms.accountPassword.isCompleted = true;
    this.formService.forms.accountPassword.isVisited = true;
    this.formService.forms.review.isCompleted = true;
    this.formService.forms.review.isVisited = true;
}
}
