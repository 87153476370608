/**
 * @purpose:
 * @ created:
 * @ last updated by: Hari  (5th June, 2018)
 * @created on:
 *
 */
import { Directive, OnInit, HostListener, Renderer2, ElementRef, Input, Output, EventEmitter, SecurityContext } from '@angular/core';
import { Subject ,  Observable, pipe , from } from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgControl } from '@angular/forms';
import { isNil, delay, filter } from 'lodash';
import { ConfigResolverService } from '../../config/external-config/service/config-resolver.service';
import { Utils } from '../utils/utils.class';
import { SanitizePipe } from '../pipes/sanitize.pipe';
import { HttpClient } from '@angular/common/http';
@Directive({
    selector: '[appContextualSearch]',
    providers: [SanitizePipe ]
})

export class ContextualSearchDirective implements OnInit {
    @Output()
    compSymbolValue: EventEmitter<any> = new EventEmitter();
    @Output()
    comSymbolBlurEvent: EventEmitter<any> = new EventEmitter();
    // search urls are being loaded from dev.en.json now.
    public searchUrl = '';
    public searchUrl2 = '';
    public listOfCompanies = [];             // this will hold final list of companies
    public searchTerm$ = new Subject<string>();
    public searchId: any;
    public name: string = null;
    public symbol: string = null;
    public resData = null;
    public parent = null;
    public listContainer = null;
    public elementType: any = null;
    public updateCountrySymbolModel: any;
    public isError: Boolean = false;
    private config;

    @Input() component: any;

    constructor(
        private configService: ConfigResolverService<any>,
        private sanitizer: SanitizePipe,
        public renderer: Renderer2,
        private elem: ElementRef,
        private http: HttpClient,
        public control: NgControl) {
        this.search(this.searchTerm$).subscribe();
    }

    ngOnInit() {
        this.config = this.configService.getConfig();
        if (this.config !== null) {
            this.searchUrl = this.config.CONTEXTUAL_SEARCH_ENDPOINT_1;
            this.searchUrl2 = this.config.CONTEXTUAL_SEARCH_ENDPOINT_2;
        }
    }

    @Input() set contextualSearch(obj: any) {
        this.searchId = obj.id;
        this.elementType = obj.type;
    }

    @HostListener('focus', ['$event'])
    onfocus(event: KeyboardEvent) {
        this.isError = false;
    }

    @HostListener('keyup', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        // could need to capture key events?
        if (this.control.value && this.control.value.length > 0 && event.keyCode !== 9) {
            this.searchTerm$.next(this.control.value);   // commented this line to avoid search functionality temporarily.
        }

    }



    search(terms: Observable<string>) {
        return terms.pipe(
        debounceTime(700),
        distinctUntilChanged(),
        switchMap(term => this.searchEntries(term))
    );
     }


    searchEntries(term) {
        const returnData = this.http.jsonp(this.searchUrl + term + this.searchUrl2,'JSONP_CALLBACK')
            .toPromise()
            .then(res=> {
                this.resData =res;
                this.resData.data[0] = filter(this.resData.data[0], (data) => {
                    return data.it !== 'ETF';
                });
                this.constructList(this.resData.data);
            })
            .catch(e => {
                this.constructList(null);
                // console.log('err' + e);
            });
        return returnData;
    }

    removeOldContainer() {
        const isContainer = <HTMLInputElement>document.getElementById('listContainer');
        if (isContainer) {
            this.renderer.removeChild(this.parent, this.listContainer);
        }
    }

    @HostListener('document:click', ['$event'])
    public onClick(targetElement) {
        if (this.isError) {
            this.comSymbolBlurEvent.emit({
                name: null,
                symbol: null,
                data: null,
                custData: {
                    country: 'CA',
                    exchange: null,
                    valueObj: {
                        type: this.elementType,
                        value: this.elem.nativeElement.value
                    },
                    isError: true
                }
            });
        }
        delay(() => this.removeOldContainer(), 400);
    }

    constructList(data) { // Construction Of List Fetched from Service
        this.removeOldContainer();
        const dataObject = data;
        if (!isNil(dataObject) && dataObject.length > 0) {
            this.isError = true;
            this.listContainer = this.renderer.createElement('div');
            this.parent = this.renderer.parentNode(this.elem.nativeElement);
            this.renderer.appendChild(this.parent, this.listContainer);
            this.renderer.setAttribute(this.listContainer, 'id', 'listContainer');
            this.renderer.setAttribute(this.listContainer, 'class', 'contextual-search');
            const ulContainer = this.renderer.createElement('ul');
            this.renderer.setAttribute(this.listContainer, 'tabindex', '0');
            this.renderer.appendChild(this.listContainer, ulContainer);

            if (dataObject.length >= 1) {
                for (let i = 0; i < dataObject[0].length; i++) {

                    this.name = dataObject[0][i].name;
                    this.symbol = dataObject[0][i].symbol;
                    const listItem = this.renderer.createElement('li');
                    const atag = this.renderer.createElement('a');
                    this.renderer.appendChild(ulContainer, listItem);
                    this.renderer.setAttribute(atag, 'href', '');
                    // added above 'javascript://' as in jquery '#' is redirecting to select account page
                    let itemName;
                    //  this.renderer.setAttribute(atag, 'tagId', String(i));
                    this.renderer.setProperty(atag, 'tagId', String(i));
                    if (this.elementType.toLowerCase() === 'symbol') {
                        this.renderer.setAttribute(atag, this.sanitizer.transform(SecurityContext.HTML, 'id'),this.sanitizer.transform(SecurityContext.HTML, dataObject[0][i].name));
                        itemName = this.renderer.createText(this.sanitizer.transform(SecurityContext.HTML, dataObject[0][i].symbol));
                    } else {
                        this.renderer.setAttribute(atag, this.sanitizer.transform(SecurityContext.HTML, 'id'), this.sanitizer.transform(SecurityContext.HTML, dataObject[0][i].symbol));
                        itemName = this.renderer.createText(this.sanitizer.transform(SecurityContext.HTML, dataObject[0][i].name));
                    }
                    this.renderer.appendChild(listItem, atag);
                    this.renderer.appendChild(atag, itemName);
                    // below condition set the focus to first element for search
                    if (i === 0) {
                        atag.focus();
                    }
                    // Below condition removes the search list if blur event is fires from last element of the list
                    if (i === dataObject[0].length - 1) {
                        this.renderer.listen(atag, 'blur', (event) => {
                            if (this.isError) {
                                this.comSymbolBlurEvent.emit({
                                    name: null,
                                    symbol: null,
                                    data: null,
                                    custData: {
                                        country: 'CA',
                                        exchange: null,
                                        valueObj: {
                                            type: this.elementType,
                                            value: this.elem.nativeElement.value
                                        },
                                        isError: true
                                    }
                                });
                            }
                            delay(() => this.removeOldContainer(), 400);
                        });
                    }
                    this.renderer.listen(atag, 'click', (event) => {  // Adding click Handler to the list items
                        this.isError = false;
                        // Do something with 'event'
                        event.preventDefault();
                        const secondEle = <HTMLInputElement>document.getElementById(this.searchId);
                        // below line is emitting data to parent element to update the model of company name and company symbol
                        this.compSymbolValue.emit({
                            name: this.elementType.toLowerCase() === 'name' ? event.target.text : event.target.id,
                            symbol: this.elementType.toLowerCase() === 'symbol' ? event.target.text : event.target.id,
                            data: dataObject[0][event.target.tagId],
                            custData: {
                                country: 'CA',
                                exchange: null,
                                valueObj: {
                                    type: this.elementType,
                                    value: null
                                },
                                isError: false
                            }
                        });
                        this.removeOldContainer();
                    });

                    this.renderer.listen(atag, 'keydown', (event: KeyboardEvent) => {
                        var altKey = event.altKey;
                        let parentElement = ulContainer;
                        let nodes = parentElement.querySelectorAll('a');

                      if (event.ctrlKey || event.shiftKey) {
                        return;
                      }
                      switch (event.key) {
                        case 'Down':
                        case 'ArrowDown':

                              if (i>=0) {
                                  nodes[i+1].focus();
                              } else {
                                  nodes[0].focus();
                              }
                          break;

                        case 'Up':
                        case 'ArrowUp':

                            if (i>=1) {
                              nodes[i-1].focus();
                            }
                          break;
                      }
                    })

                }
            }

        } else {
            this.isError = true;
            this.compSymbolValue.emit({
                name: null,
                symbol: null,
                data: null,
                custData: {
                    country: 'CA',
                    exchange: null,
                    valueObj: {
                        type: this.elementType,
                        value: this.elem.nativeElement.value
                    },
                    isError: this.isError
                }
            });
        }
    }


}


