import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-chat-container',
  templateUrl: './live-chat-container.component.html',
  styleUrls: ['./live-chat-container.component.scss']
})
export class LiveChatContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
