import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LiveChatService {
  private appId;
  private isPilotUser = false;
  private sdk;
  private currentPlatform = "desktop";
  private $isLiveChatEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  liveChatDisplayIconState: BehaviorSubject<string> = new BehaviorSubject('close');
  liveChatData: BehaviorSubject<any> = new BehaviorSubject(false);
  aiqChatInitiated = new BehaviorSubject<boolean>(false);
  parser = new DOMParser();
  currentPageUrl = "";
  private aiqInitialized = false;

  constructor(
    // private sessionStorageService: SessionStorageService,
  ) {
  }

  loadLiveChat(nativeElement) {
      console.log('**** In loadLiveChat... vendorService.loadAgentIQScripts... ');
      this.loadAgentIQScripts(nativeElement);
  }

  loadAgentIQScripts(element?: any) {
    const appLang = 'en';
    console.log('inside vendor script loadAgentIQ');
    if (!(window as any).__aiq) {
      let scriptPath;
      // lynq-bil.bmo.com
      // lynq-ws-bil.bmo.com  
      // lynq-bil.qa.bmo.com
      // lynq-ws-bil.qa.bmo.com
      scriptPath = 'https://lynq-bil.qa.bmo.com/webchat.js?auto_render=false',
      console.log('inside non aiq instance flow 1');
      this.loadScript(scriptPath, false, true, element);
    } else {
      console.log('*** live chat getAiqSDKInstance in else');
      this.checkAndLoadsdk(element, appLang);
    }
  }

  loadScript(src: string, loadOnTop?: boolean, isAgentIQ?: boolean, element?: any, isModule?: boolean) {
    const appLang = 'en';
    // const script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.async = !!isAgentIQ;
    // script.src = src;
    const script =  document.getElementById('aiqScript');
      if (isAgentIQ) {
        // script.id = 'aiqScript';
        console.log('*** live chat inside isAgentIQ');
        script.onload = () => {
          if ((window as any).__aiq) {
            this.checkAndLoadsdk(element, appLang);
          } else {
            console.log('AIQ not loaded', (window as any).__aiq);
          }
        };
        script.onerror = error => {
          console.log('aiq script error', error);
        }
        // element?.appendChild(script);
      } else {
        const head = document.getElementsByTagName('head')[0];
        head?.appendChild(script);
      }
  }

  checkAndLoadsdk(element: any, appLang: string) {
    this.sdk = (window as any).__aiq;
    console.log('live chat sdk', this.sdk);
    this.configureAIQ(element, appLang);
    console.log('inside else for sdk exists');
  }

  // Checks if AIQ scripts are loaded and SDK is available
  checkIfAiqSdkAvailable() {
    if (!this.sdk) {
      if (!(window as any).__aiq) {
        return false;
      } else {
        this.sdk = (window as any).__aiq;
        return true;
      }
    } else {
      return true;
    }
  }

  rerenderLiveChat() {
    this.sdk = (window as any).__aiq;
    console.log('**** inside rerenderLiveChat... ');
    this.renderAgentIQChatWindow(); // mount web-component
    this.agentIQWorkFlowTrigger();
  }

  configureAIQ(element: any, appLang: string) {
    if (this.aiqInitialized) {
      return;
    }
    console.log('in configureAIQ');
    this.configureAgentIQSDKForInitialLoad(element, appLang); // should not be called on reopen
    this.agentIQEventsSubscriptions();
    this.renderAgentIQChatWindow(); // mount web-component
      this.agentIQWorkFlowTrigger();
    this.aiqInitialized = true;
  }

  removeHttpsProtocol(url): string {
    console.log('*** live chat url', url);
    if (url && url.startsWith('https://')) {
      const https = 'https://';
      return url?.slice(https.length);
    }
    return url;
  }

  configureAgentIQSDKForInitialLoad(element: any, appLang: string) {
    console.log('configureAgentIQSDKForInitialLoad sdk', this.sdk);
    this.sdk.use_sso = false; // run SDK for authenticated customer
    this.sdk.app_root_element = document.getElementById('LiveChatContent'); // provide div element that agent renders;
    this.sdk.locale = appLang; // (optional) provide locale info
    this.sdk.chat_wrapper = 'web-component'; // indicate that chat should use web-component
  }

  renderAgentIQChatWindow() {
    this.sdk?.renderChat();
  }

  agentIQEventsSubscriptions() {
    this.agentIQMinimizeOrCloseBtnClicked();
    this.agentIQMessageOrAgentAddedEventTrigger();
    // this.agentIQAuthCallToCompleteSSO();
  }

  agentIQMinimizeOrCloseBtnClicked() {
    this.sdk.on('ui', ({ component, action }) => {
      if (component === 'minimize_button') {
        console.log('minimize clicked');
        // this.$triggerLiveChatMinimize.next(true);
      }
      if (component === 'close_button') {
        console.log('close clicked');
        // this.$triggerLiveChatClose.next(true);
      }
      if (component === 'auth_error_close_button') {
        // this.isBrowserRefreshForAIQManipulation = true;
        console.log('auth_error_close_button live chat popup close button clicked');
        // this.$triggerLiveChatCloseWithOutConfirmation.next(true);
      }
      if (component === 'auth_error_retry_button') {
        console.log('live chat popup retry button clicked');
      }
      // do activity check
    });
  }

  agentIQMessageOrAgentAddedEventTrigger() {
    this.sdk.on('chat', ({ type, payload }) => {
      console.log('*** live chat message chat');
      if (type === 'message_added') {
      if (payload?.sender_type === 'agents') {
          console.log('*** live chat message chat by agents and do nothing');
        }
      } else if (type === 'agent_added') {
      }
    });
  }

  agentIQDeleteSession() {
    this.sdk?.clearSession();
  }

  agentIQRemoveFromDom() {
    this.sdk?.unMountChat();
  }

  agentIQCloseConversation(isFromMAppLogout?: boolean) {
    // Remove the current workflow from session
    // Call close conversation AIQ API to terminate the ongoing conversation
    const closeConversationWf = '';
    console.log('**** closing AIQ conversation.. calling APi with WF: ', closeConversationWf);
    console.log('*** live chat sdk inside wf close', this.sdk);
    try {
      if (this.sdk) {
        this.sdk.triggerWorkflow(closeConversationWf)
          .then(() => {
            // remove livechat widget from shadow DOM
            console.log('**** unmounting live chat... ');
            this.agentIQRemoveFromDom();
          });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  // Terminate the live chat but will keep the session
  terminateLiveChat(isFromMAppLogout?) {
    // 1. Conversation will be close on click of "X" by calling close conversation AIQ API
    // 2. unmount the live chat
    this.agentIQCloseConversation(isFromMAppLogout);
  }

  agentIQWorkFlowTrigger() {
  let workflow = this.appId === 'il-sd' ? "wf_auth_sd_general" : "wf_auth_ad_general";
  console.log('**** workflow name: ', workflow);
    this.sdk?.triggerWorkflow(workflow, {
      ignore_on_active_conversation: true
    });
  }
}
